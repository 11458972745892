import {
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  WithStyles,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { setTitle, showGlobalProgressModal } from '@meeva/service-client-core/redux/interface/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import * as PackagingSlipHelper from '../utils/packagingSlipHelper';

interface TableDataInterface {
  serialFormId?: string;
  createdAt: Date;
  detailUrl: string;
}

const styles = () =>
  createStyles({
    paper: {
      padding: '5px',
      display: 'flex',
      flexDirection: 'column',
    },
  });

const PackagingSlipListView = ({ classes }: WithStyles<typeof styles>) => {
  const [tableData, setTableData] = useState<TableDataInterface[]>([]);
  const [pagination, setPagination] = useState({ currentPage: 0, qtyRows: 10 });
  const dispatch = useDispatch();
  const history = useHistory();

  const getNormalizeTableData = async () =>
    (await PackagingSlipHelper.getEntries()).map(({ id, createdAt, serialFormId }) => ({
      serialFormId,
      createdAt: new Date(createdAt),
      detailUrl: `/packagingSlip/${id}`,
    }));

  useEffect(() => {
    dispatch(setTitle(`Lieferung`));

    dispatch(showGlobalProgressModal());
    (async () => {
      setTableData(await getNormalizeTableData());
      dispatch(showGlobalProgressModal(false));
    })();
  }, [dispatch]);

  return (
    <Container>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lieferscheinnr.</TableCell>
                <TableCell>Erstellt am</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length === 0 ? (
                <TableRow>
                  <TableCell align={'center'} colSpan={3}>
                    Keine Lieferung geladen
                  </TableCell>
                </TableRow>
              ) : (
                tableData
                  .slice(
                    pagination.currentPage * pagination.qtyRows,
                    pagination.currentPage * pagination.qtyRows + pagination.qtyRows
                  )
                  .map((slip, i) => (
                    <TableRow key={i} onClick={() => history.push(slip.detailUrl)}>
                      <TableCell>{slip.serialFormId}</TableCell>
                      <TableCell>{new Intl.DateTimeFormat('de-DE').format(slip.createdAt)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => history.push(slip.detailUrl)}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            labelRowsPerPage="Zeilen:"
            count={tableData.length}
            page={pagination.currentPage}
            rowsPerPage={pagination.qtyRows}
            onPageChange={(_, value) => setPagination((prevState) => ({ ...prevState, currentPage: value }))}
            onRowsPerPageChange={(event) =>
              setPagination((prevState) => ({
                ...prevState,
                qtyRows: Number(event.target.value) || 100,
              }))
            }
          />
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default withStyles(styles)(PackagingSlipListView) as React.ComponentType;
