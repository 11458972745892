import { ThunkDispatch } from 'redux-thunk';

import { resetSnack, sendMessageToSyncWorker, setSnack } from './actions';

export enum UpdateCheckResult {
  UPDATE_ERROR = -1,
  NO_UPDATE_FOUND = 0,
  UPDATE_AVAILABLE = 1,
}

export const triggerBackgroundSync =
  (type?: string, force = false) =>
  async (dispatch: ThunkDispatch<any, null, any>) => {
    dispatch(sendMessageToSyncWorker({ type: type || 'full', force }));
  };

export const checkForAppUpdate =
  (showNotification = true) =>
  async (dispatch: ThunkDispatch<any, null, any>): Promise<UpdateCheckResult> => {
    try {
      const worker = await navigator.serviceWorker.ready;
      const result: ServiceWorkerRegistration | void = (await worker.update()) as any;

      // keine Registration erhalten, z.B. Browser unterstützt keine Service-Worker oder Dev-Umgebung
      if (!result) {
        if (showNotification) {
          dispatch(
            setSnack({
              text: 'Kein Update gefunden',
              severity: 'info',
              autoHideDuration: 3000,
            })
          );
        }
        return UpdateCheckResult.NO_UPDATE_FOUND;
      }

      // keine neue Version gefunden
      if (!result.installing && !result.waiting) {
        if (showNotification) {
          dispatch(
            setSnack({
              text: 'Kein Update gefunden',
              severity: 'info',
              autoHideDuration: 3000,
            })
          );
        }
        return UpdateCheckResult.NO_UPDATE_FOUND;
      }

      if (showNotification) {
        dispatch(
          setSnack({
            text: 'Update gefunden, wird installiert...',
            severity: 'success',
            autoHideDuration: 10000,
          })
        );
      }

      if (result.waiting) {
        // Neue Version gefunden und wartet auf Installation
        // ⇾ sofortige Installation
        result.waiting.postMessage({ type: 'SKIP_WAITING' });
      } else if (result.installing) {
        // Neue Version gefunden und wird im Hintergrund installation
        // ⇾ auf Fertigstellung warten, dann sofortige Installation
        result.installing.addEventListener('statechange', function () {
          if (this.state === 'installed') {
            this.postMessage({ type: 'SKIP_WAITING' });
          }
        });
      }

      return UpdateCheckResult.UPDATE_AVAILABLE;
    } catch (e) {
      if (showNotification) {
        dispatch(
          setSnack({
            text: 'Kein Update gefunden',
            severity: 'info',
            autoHideDuration: 3000,
          })
        );
      }
    }

    return UpdateCheckResult.UPDATE_ERROR;
  };

export { resetSnack, setSnack };
