import Dexie, { Transaction } from 'dexie';

import { BusinessUnitInterface } from '../../../../interfaces/BusinessUnitInterface';
import {
  DistributionPackagingSlipDistributionTaskInterface,
  DistributionPackagingSlipInterface,
  DistributionPackagingSlipQueueTaskInterface,
} from '../../../../interfaces/DistributionPackagingSlipInterface';
import { Event } from '../../../../interfaces/EventInterface';
import { IncomingTransferInterface } from '../../../../interfaces/IncomingTransferInterface';
import {
  ItemCollectionListInterface,
  ItemCollectorTaskInterface,
} from '../../../../interfaces/ItemCollectorTaskInterface';
import {
  ItemInventoryShelf,
  ItemInventoryStockTake,
  ItemInventoryTask,
} from '../../../../interfaces/ItemInventoryInterface';
import { ItemInventoryMetricInterface } from '../../../../interfaces/ItemInventoryMetricInterface';
import {
  ItemLabelInterface,
  ItemLabelsCollection,
  ItemLabelsCollectionStatus,
} from '../../../../interfaces/ItemLabelsCollectionInterface';
import { ItemInterface, ItemShelfInterface } from '../../../../interfaces/ItemListInterface';
import { OrderProposalTaskInterface } from '../../../../interfaces/OrderProposalTaskInterface';
import { RequestTransferDocumentInterface } from '../../../../interfaces/RequestTransferDocumentInterface';
import { StockMovementTask } from '../../../../interfaces/stockMovementTaskInterface';
import { StockTransferStatus, StockTransferTaskInterface } from '../../../../interfaces/StockTransferInterface';
import { SupplierInterface } from '../../../../interfaces/SupplierInterface';

export interface ConfigurationKeyValue<T = any> {
  key: string;
  value: T;
}

export class StorageClient extends Dexie {
  public readonly configuration: Dexie.Table<ConfigurationKeyValue, string>;
  public readonly items: Dexie.Table<ItemInterface, string>;
  public readonly itemShelves: Dexie.Table<ItemShelfInterface, string>;
  public readonly businessUnits: Dexie.Table<BusinessUnitInterface, string>;
  public readonly inventoryTasks: Dexie.Table<ItemInventoryTask, string>;
  public readonly inventoryTasksByShelves: Dexie.Table<ItemInventoryShelf, number>;
  public readonly stockTakeResults: Dexie.Table<ItemInventoryStockTake, [string, string]>;
  public readonly stockMovementTasks: Dexie.Table<StockMovementTask, string>;
  public readonly itemLabelsCollection: Dexie.Table<ItemLabelsCollection, string>;
  public readonly itemCollectorTasks: Dexie.Table<ItemCollectorTaskInterface, string>;
  public readonly suppliers: Dexie.Table<SupplierInterface, string>;
  public readonly orderProposalTasks: Dexie.Table<OrderProposalTaskInterface, string>;
  public readonly events: Dexie.Table<Event, string>;
  public readonly distributionPackagingSlips: Dexie.Table<DistributionPackagingSlipInterface, string>;
  public readonly distributionPackagingSlipDistributionTasks: Dexie.Table<
    DistributionPackagingSlipDistributionTaskInterface,
    string
  >;
  public readonly distributionPackagingSlipQueueTasks: Dexie.Table<DistributionPackagingSlipQueueTaskInterface, string>;
  public readonly stockTransferTasks: Dexie.Table<StockTransferTaskInterface, string>;
  public readonly requestTransferDocuments: Dexie.Table<RequestTransferDocumentInterface, string>;
  public readonly incomingTransfers: Dexie.Table<IncomingTransferInterface, string>;
  public readonly itemInventoryMetrics: Dexie.Table<ItemInventoryMetricInterface, string>;
  public readonly itemCollectionLists: Dexie.Table<ItemCollectionListInterface, string>;

  protected _storage!: Dexie;

  constructor() {
    super((process.env.REACT_APP_DB_PREFIX || '') + 'service_client');

    this.version(8).stores({
      configuration: '&key',
      items: `id, &itemNo, *posIdentities, *suppliers`,
      businessUnits: `id, number, name`,
      inventoryTasks: `id, itemId, reason`,
      stockTakeResults: `[itemId+location], itemId, timestamp, status`,
      stockMovementTasks: `id, status`,
    });

    // benötigt damit Dexie funktioniert
    this.version(9).stores({});
    this.version(10).stores({});

    this.version(11).stores({
      itemLabelsCollection: `id`,
    });

    this.version(12).stores({
      inventoryTasks: `id, itemId, reason, shelfNumber`,
    });

    this.version(13).stores({
      // Index auf Object-Properties in einem Array sind in IndexedDB nicht möglich
      // https://github.com/dfahlander/Dexie.js/issues/86#issuecomment-91358221
      // Daher müssen wir die IDs selber auflösen und in Array packen welches dann indexiert werden kann
      inventoryTasksByShelves: `number, *taskItemIds, *taskIds`,
    });

    this.version(14).stores({
      suppliers: `id, number, name`,
    });

    this.version(15).stores({
      orderProposalTasks: `id, status`,
    });

    this.version(16).stores({
      events: `id, instruction, status, [instruction+status]`,
    });

    this.version(17).stores({
      itemShelves: `id, number`,
      items: `id, &itemNo, *posIdentities, *suppliers, *shelves`,
    });

    this.version(18).stores({
      items: `id, &itemNo, *customItemNo, *posIdentities, *suppliers, *shelves`,
    });

    this.version(19)
      .stores({
        itemLabelsCollection: `id, timestamp, status`,
      })
      .upgrade((transaction: Transaction) =>
        transaction
          .table('itemLabelsCollection')
          .toCollection()
          .modify((collection: ItemLabelsCollection & { itemsLabels: ItemLabelInterface[] | undefined }) => {
            const itemLabels = collection.itemsLabels ?? [];

            collection.itemLabels = itemLabels;
            collection.timestamp = new Date().toISOString();
            collection.status =
              itemLabels.length > 0 ? ItemLabelsCollectionStatus.READY : ItemLabelsCollectionStatus.DRAFT;
          })
      );

    this.version(20).stores({
      itemCollectorTasks: `id, status, timestamp`,
    });

    this.version(21).stores({
      distributionPackagingSlips: `id`,
      distributionPackagingSlipItemTasks: `id, packagingSlipId, status`,
      distributionPackagingSlipItemScanQueueTasks: `id, packagingSlipId`,
    });

    //Renamed stores for more clarity
    this.version(22).stores({
      distributionPackagingSlips: `id`,
      distributionPackagingSlipDistributionTasks: `id, packagingSlipId, status`,
      distributionPackagingSlipQueueTasks: `id, packagingSlipId`,
    });

    this.version(23).stores({
      stockTransferTasks: `[destinationBusinessUnitId+containerCode]`,
    });

    this.version(24)
      .stores({
        stockTransferTasks: null,
        stockTransferTasksTemp: `++id, [destinationBusinessUnitId+containerCode], status`,
      })
      .upgrade(async (transaction: Transaction) => {
        const stockTransferTasks = await transaction.table('stockTransferTasks').toArray();

        await transaction
          .table('stockTransferTasksTemp')
          .bulkAdd(stockTransferTasks.map((task) => ({ ...task, status: StockTransferStatus.READY })));
      });

    this.version(25)
      .stores({
        stockTransferTasks: `++id, [destinationBusinessUnitId+containerCode], status`,
        stockTransferTasksTemp: null,
      })
      .upgrade(async (transaction: Transaction) => {
        const stockTransferTasks = await transaction.table('stockTransferTasksTemp').toArray();

        await transaction.table('stockTransferTasks').bulkAdd(stockTransferTasks);
      });

    this.version(26).stores({
      requestTransferDocuments: `id, status, containerCode`,
      requestTransferDocumentTasks: `id, status, [status+containerCode]`,
    });

    this.version(27).stores({
      incomingTransfers: `id, status, containerCode`,
    });

    this.version(28).stores({
      itemInventoryMetrics: `[itemId+type], itemId, type`,
    });

    this.version(29).stores({
      itemCollectionLists: `id, status, createdAt`,
    });

    this.version(30).stores({
      itemCollectorTasks: `id, status, timestamp, type`,
    });

    this.version(31).stores({
      items: `id, &itemNo, *customItemNo, *scanCodes, *suppliers, *shelves`,
    });

    this.version(32).stores({
      events: `id, instruction, status, [instruction+status], type`,
    });

    this.configuration = this.table('configuration');
    this.items = this.table('items');
    this.businessUnits = this.table('businessUnits');
    this.inventoryTasks = this.table('inventoryTasks');
    this.inventoryTasksByShelves = this.table('inventoryTasksByShelves');
    this.stockTakeResults = this.table('stockTakeResults');
    this.stockMovementTasks = this.table('stockMovementTasks');
    this.itemLabelsCollection = this.table('itemLabelsCollection');
    this.itemCollectorTasks = this.table('itemCollectorTasks');
    this.suppliers = this.table('suppliers');
    this.orderProposalTasks = this.table('orderProposalTasks');
    this.events = this.table('events');
    this.itemShelves = this.table('itemShelves');
    this.distributionPackagingSlips = this.table('distributionPackagingSlips');
    this.distributionPackagingSlipDistributionTasks = this.table('distributionPackagingSlipDistributionTasks');
    this.distributionPackagingSlipQueueTasks = this.table('distributionPackagingSlipQueueTasks');
    this.stockTransferTasks = this.table('stockTransferTasks');
    this.requestTransferDocuments = this.table('requestTransferDocuments');
    this.incomingTransfers = this.table('incomingTransfers');
    this.itemInventoryMetrics = this.table('itemInventoryMetrics');
    this.itemCollectionLists = this.table('itemCollectionLists');
  }

  public async bulkUpdate(tableName: string, data: any[]) {
    const table = this._storage.table(tableName);

    for (const elem of data) {
      try {
        const doc = await table.get(elem.id);
        if (doc) {
          elem._rev = doc._rev;
        }
      } catch (e) {
        // Wenn der Artikel nicht gefunden wird, so wird er neu erstellt
      }

      await table.put(elem);
    }
  }
}

export const DB = new StorageClient();
