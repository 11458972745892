import successSound from '../assets/audio/beep.mp3';
import warningSound from '../assets/audio/double-beep.mp3';
import errorSound from '../assets/audio/error.mp3';
import AppConfig from '../modules/app/config';

type StatusType = 'success' | 'warning' | 'error';
type SpecifiedForType = 'item' | 'businessUnit' | 'container' | 'others';

const playSound = (status: StatusType, specifiedFor: SpecifiedForType) => {
  const { enabled = false, audioOnStatus = ['all'], audioOnType = ['all'] } = AppConfig.getConfig().audioFeedback || {};

  if (
    enabled &&
    (audioOnStatus.includes(status) || audioOnStatus.includes('all')) &&
    (audioOnType.includes(specifiedFor) || audioOnType.includes('all'))
  ) {
    const audio = new Audio();

    switch (status) {
      case 'success':
        audio.src = successSound;
        break;
      case 'warning':
        audio.src = warningSound;
        break;
      case 'error':
      default:
        audio.src = errorSound;
        break;
    }

    audio.play();
  }
};

const playSuccessSound = (type?: SpecifiedForType) => playSound('success', type || 'others');
const playWarningSound = (type?: SpecifiedForType) => playSound('warning', type || 'others');
const playErrorSound = (type?: SpecifiedForType) => playSound('error', type || 'others');

export { playErrorSound, playSuccessSound, playWarningSound };
