import { CircularProgress, Modal, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

import { getGlobalProgressModal } from '../../../redux/interface/selectors';

const styles = () =>
  createStyles({
    loadingModal: {
      display: 'flex',
    },
    loadingSpinner: {
      margin: 'auto',
    },
  });

export interface GlobalProgressModalProps extends WithStyles {
  status: {
    visible: boolean;
  };
}

const GlobalProgressModal = ({ classes, status }: GlobalProgressModalProps) => {
  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      data-cy="global-progress-modal"
      className={classes.loadingModal}
      open={status.visible}
    >
      <CircularProgress className={classes.loadingSpinner} />
    </Modal>
  );
};

export default connect((state) => ({ status: getGlobalProgressModal(state) }))(withStyles(styles)(GlobalProgressModal));
