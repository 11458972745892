import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import {
  IncomingTransferInterface,
  IncomingTransferLineItemInterface,
  IncomingTransferStatus,
} from '@meeva/service-client-core/interfaces/IncomingTransferInterface';
import { setSnack, setTitle, showGlobalProgressModal } from '@meeva/service-client-core/redux/interface/actions';
import { triggerBackgroundSync } from '@meeva/service-client-core/redux/interface/operations';
import { playErrorSound, playSuccessSound } from '@meeva/service-client-core/utils/audioHelper';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import * as IncomingTransferHelper from '../utils/incomingTransferHelper';
import { setTransfersReady } from '../utils/incomingTransferHelper';

const useDefaultStyles: () => ClassNameMap<string> = makeStyles({
  paper: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  paperSearch: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  rowSelected: {
    backgroundColor: '#FFC547E0',
  },
  rowSend: {
    backgroundColor: '#69af4c99',
  },
  rowError: {
    backgroundColor: '#FF00003A',
  },
});

const IncomingTransferList = () => {
  const [scannedCode, setScannedCode] = useState<string>('');
  const [pagination, setPagination] = useState({ currentPage: 0, qtyRows: 10 });
  const [selectedContainers, setSelectedContainers] = useState<string[]>([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useDefaultStyles();
  const scannerInputRef = useRef<HTMLInputElement>();
  const tableData: IncomingTransferInterface[] = useLiveQuery(() => IncomingTransferHelper.getEntries()) || [];

  useEffect(() => {
    dispatch(setTitle('Filialtausch Annahme'));
  }, []);

  useEffect(() => {
    if (scannerInputRef.current) {
      scannerInputRef.current.focus();
    }
  }, [scannedCode]);

  const handleScan = () => {
    const selectedEntry = tableData.find((data) => data.containerCode === scannedCode);

    if (!selectedEntry) {
      playErrorSound('item');
      return dispatch(
        setSnack({
          autoHideDuration: 5000,
          severity: 'warning',
          text: 'Der gesuchte Container konnte nicht gefunden werden',
        })
      );
    }

    if (selectedEntry.status !== IncomingTransferStatus.DRAFT) {
      playErrorSound('item');
      return dispatch(
        setSnack({
          autoHideDuration: 5000,
          severity: 'warning',
          text: 'Dieser Container wurde bereits für die Warenannahme gespeichert.',
        })
      );
    }

    setScannedCode('');
    playSuccessSound('item');
    setSelectedContainers([...selectedContainers, selectedEntry.id]);
  };

  const handleSubmitIncoming = async () => {
    dispatch(showGlobalProgressModal(true));
    try {
      await setTransfersReady(selectedContainers);
      await dispatch(triggerBackgroundSync('incomingTransfers', true));
    } catch (error) {
      dispatch(
        setSnack({
          text: 'Es ist ein Fehler aufgetreten',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );

      console.error(error);
    }
    setScannedCode('');
    setSelectedContainers([]);
    setIsConfirmationDialogOpen(false);
    dispatch(showGlobalProgressModal(false));
  };

  const statusColor = (status: IncomingTransferStatus) => {
    switch (status) {
      case IncomingTransferStatus.READY:
        return classes.rowSend;
      case IncomingTransferStatus.ERROR:
        return classes.rowError;
      default:
        return '';
    }
  };

  return (
    <Container>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Barcode scannen oder eingeben"
              variant="outlined"
              inputRef={scannerInputRef}
              value={scannedCode}
              onChange={(event) => setScannedCode(event.target.value)}
              onKeyDown={(event) => {
                if ('Enter' === event.key || '13' === event.code) {
                  event.preventDefault();
                  handleScan();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Suche starten" onClick={() => handleScan} edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedContainers.length}
              onClick={() => setIsConfirmationDialogOpen(!isConfirmationDialogOpen)}
              fullWidth
            >
              Warenannahmen speichern
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Container</TableCell>
                <TableCell>Quell-Filiale</TableCell>
                <TableCell>Artikel-Anzahl</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length ? (
                tableData
                  .slice(
                    pagination.currentPage * pagination.qtyRows,
                    pagination.currentPage * pagination.qtyRows + pagination.qtyRows
                  )
                  .map((data, i) => (
                    <TableRow
                      key={i}
                      className={`${selectedContainers.includes(data.id) ? classes.rowSelected : ''} ${statusColor(
                        data.status
                      )}`}
                    >
                      <TableCell>{data.containerCode}</TableCell>
                      <TableCell>
                        {data.sourceBusinessUnit.number
                          ? `${data.sourceBusinessUnit.number} - ${data.sourceBusinessUnit.name}`
                          : data.sourceBusinessUnit.name}
                      </TableCell>
                      <TableCell>
                        {data.lineItems.reduce(
                          (pv: number, item: IncomingTransferLineItemInterface) => pv + item.unitCount,
                          0
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => history.push(`${history.location.pathname}/${data.id}`)}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Keine Lieferung geladen
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            labelRowsPerPage="Zeilen:"
            count={tableData.length}
            page={pagination.currentPage}
            rowsPerPage={pagination.qtyRows}
            onPageChange={(_, value) => setPagination((prevState) => ({ ...prevState, currentPage: value }))}
            onRowsPerPageChange={(event) =>
              setPagination((prevState) => ({
                ...prevState,
                qtyRows: Number(event.target.value) || 100,
              }))
            }
          />
        </TableContainer>
      </Paper>
      <Dialog open={isConfirmationDialogOpen} onClose={() => setIsConfirmationDialogOpen(false)}>
        <DialogTitle>Filialtausch-Annahme speichern?</DialogTitle>
        <DialogContent>
          <DialogContentText>Sind Sie sicher, dass Sie die aktuellen Warenannahmen speichern wollen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsConfirmationDialogOpen(false)} fullWidth>
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmitIncoming} fullWidth>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default IncomingTransferList;

export { useDefaultStyles };
