import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { BusinessUnitInterface } from '@meeva/erp-types-core/dist';
import {
  RequestTransferDocumentInterface,
  RequestTransferDocumentLineItemInterface,
  RequestTransferDocumentLineItemTask,
} from '@meeva/service-client-core/interfaces/RequestTransferDocumentInterface';
import {
  StockTransferStatus,
  StockTransferTaskInterface,
} from '@meeva/service-client-core/interfaces/StockTransferInterface';
import AppConfig from '@meeva/service-client-core/modules/app/config';
import {
  setNavigationUpwardTarget,
  setSnack,
  setTitle,
  showGlobalProgressModal,
} from '@meeva/service-client-core/redux/interface/actions';
import { triggerBackgroundSync } from '@meeva/service-client-core/redux/interface/operations';
import { playErrorSound, playSuccessSound } from '@meeva/service-client-core/utils/audioHelper';
import { findItemByCode } from '@meeva/service-client-core/utils/items';
import {
  createOrUpdateTask,
  createSealedContainer,
  getTasks,
  modifyTask,
  removeTask,
} from '@meeva/service-client-core/utils/stockTransferTaskHelper';
import { useLiveQuery } from 'dexie-react-hooks';
import { debounce } from 'lodash';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';

import StockTransferItemRow, { TaskRowInterface } from '../../freeStockTransfer/components/StockTransferItemRow';
import ItemRowContextMenu from '../../freeStockTransfer/components/StockTransferItemRowContextMenu';
import * as RequestStockTransferHelper from '../utils/requestStockTransferHelper';
import RequestStockTransferItemQuantityDialog from './RequestStockTransferItemQuantityDialog';
import RequestStockTransferItemRow, { ContextMenuInterface } from './RequestStockTransferItemRow';
import { useDefaultStyles } from './RequestStockTransferList';

export interface CustomRouterProps {
  id?: string;
  type?: 'businessUnit' | 'lineItem';
}

interface FormErrorStateInterface {
  containerMsg: string;
  destinationBusinessUnitMsg: string;
}

interface QuantityDialogInterface {
  id?: string;
  documentId: string;
  lineItem: RequestTransferDocumentLineItemInterface;
  containerCode?: string;
}

type Props = RouteComponentProps<CustomRouterProps>;

const ERROR_MESSAGE_MISSING_CONTAINER = 'Es muss vorher ein Container angegeben werden';
const ERROR_MESSAGE_NOT_FOUND_ITEM = 'Der gesuchte Artikel konnte nicht gefunden werden';

export type RequestTransferTaskResult = RequestTransferDocumentInterface & { individualTasks: TaskRowInterface[] };

const RequestStockTransferForm = ({ match }: Props) => {
  const [businessUnit, setBusinessUnit] = useState<BusinessUnitInterface>();
  const [requestDocuments, setRequestDocuments] = useState<RequestTransferTaskResult[]>();
  const [sealedContainerTasks, setSealedContainerTasks] = useState<TaskRowInterface[]>([]);
  const [containerCode, setContainerCode] = useState<string>('');
  const [scannedBarcode, setScannedBarcode] = useState<string>('');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [formErrorState, setFormError] = useState<FormErrorStateInterface>({
    containerMsg: '',
    destinationBusinessUnitMsg: '',
  });
  const [contextMenu, setContextMenu] = useState<ContextMenuInterface | null>(null);
  const [quantityDialog, setQuantityDialog] = useState<QuantityDialogInterface | null>(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const scannerInputRef = useRef<HTMLInputElement>();
  const defaultClasses = useDefaultStyles();

  const queuedTasks = useLiveQuery<StockTransferTaskInterface[]>(
    () => getTasks(null, match.params.id, null, 'request'),
    [match.params.id]
  );
  const declinedTasks = useLiveQuery<StockTransferTaskInterface[]>(
    () => getTasks(null, match.params.id, null, 'declineRequest'),
    [match.params.id]
  );
  const _requestDocuments = useLiveQuery<RequestTransferDocumentInterface[]>(
    async () => RequestStockTransferHelper.getEntriesByBusinessUnit(match.params.id!),
    [match.params.id]
  );
  const isDeclined = (documentId: string, lineItem: RequestTransferDocumentLineItemInterface) =>
    Boolean(
      declinedTasks?.find(
        (task) => task.requestDocumentId === documentId && task.lineItems.find((li) => li.item.id === lineItem.item.id)
      )
    );
  const notifyServiceWorker = useCallback(
    debounce(
      async () => {
        await dispatch(triggerBackgroundSync('stockTransferTasks', true));
      },
      10000,
      { maxWait: 30000 }
    ),
    []
  );

  useEffect(() => {
    dispatch(setTitle('Filialtausch Packen'));
    dispatch(setNavigationUpwardTarget(`/stock-transfer/request`));
    const { id, type } = match.params;

    const handleError = () => {
      dispatch(
        setSnack({
          autoHideDuration: 5000,
          severity: 'warning',
          text: 'Es ist ein Fehler beim Aufrufen der Liste aufgetreten',
        })
      );

      return history.goBack();
    };

    if (!id || !type) {
      return handleError();
    }
  }, [match]);

  useEffect(() => {
    if (scannerInputRef.current) {
      scannerInputRef.current.focus();
    }
  }, [scannedBarcode, requestDocuments]);

  useEffect(() => {
    if (_requestDocuments !== undefined) {
      if (!_requestDocuments || !_requestDocuments?.length) {
        dispatch(
          setSnack({
            autoHideDuration: 5000,
            severity: 'warning',
            text: 'Es ist ein Fehler beim Aufrufen der Liste aufgetreten',
          })
        );

        return history.goBack();
      }

      setBusinessUnit(_requestDocuments[0].destinationBusinessUnit);
    }
  }, [_requestDocuments]);

  useEffect(() => {
    if (_requestDocuments) {
      setRequestDocuments(
        _requestDocuments.map((document) => {
          const individualTasks: TaskRowInterface[] = [];
          for (const task of (queuedTasks || []).filter((task) => task.requestDocumentId === document.id)) {
            for (const lineItem of task.lineItems) {
              individualTasks.push({
                id: StockTransferStatus.SENT !== task.status ? task.id : undefined,
                type: 'item',
                item: lineItem.item as any,
                destinationBusinessUnit: document.destinationBusinessUnit,
                containerCode: task.containerCode,
                scannedQuantity: lineItem.unitCount,
                status: task.status,
                error: task.error,
              });
            }
          }
          return {
            ...document,
            status: [...(queuedTasks || []), ...(declinedTasks || [])]
              .filter((task) => task.requestDocumentId === document.id)
              .reduce((val, task) => Math.max(val, task.status), document.status || 0),
            individualTasks,
          };
        })
      );

      setSealedContainerTasks(
        (queuedTasks || [])
          .filter(
            (task) =>
              (StockTransferStatus.CONTAINER_SEALING === task.status ||
                StockTransferStatus.CONTAINER_SEALING_ERROR === task.status) &&
              _requestDocuments[0].destinationBusinessUnit.id === task.destinationBusinessUnitId
          )
          .map(({ id, containerCode, status }) => ({
            id,
            type: 'sealing',
            destinationBusinessUnit: _requestDocuments[0].destinationBusinessUnit,
            containerCode,
            status,
            scannedQuantity: 0,
          }))
      );
    }
  }, [_requestDocuments, queuedTasks, declinedTasks]);

  const displayErrorNotification = (message: string) => {
    dispatch(
      setSnack({
        text: message,
        severity: 'warning',
        autoHideDuration: 5000,
      })
    );
  };

  const handleItemScan = async (code: string) => {
    dispatch(showGlobalProgressModal(true));

    if (!containerCode) {
      playErrorSound('item');
      setFormError((prevState) => ({
        ...prevState,
        containerMsg: ERROR_MESSAGE_MISSING_CONTAINER,
      }));
      dispatch(showGlobalProgressModal(false));
      return displayErrorNotification(ERROR_MESSAGE_MISSING_CONTAINER);
    }

    const stockItem = await findItemByCode(code);

    if (!stockItem) {
      playErrorSound('item');
      setFormError((prevState) => ({
        ...prevState,
        containerMsg: ERROR_MESSAGE_NOT_FOUND_ITEM,
      }));

      dispatch(showGlobalProgressModal(false));
      return displayErrorNotification(ERROR_MESSAGE_NOT_FOUND_ITEM);
    }

    let documentId = null;

    documentLoop: for (const document of requestDocuments!) {
      for (const lineItem of document.lineItems) {
        if (
          lineItem.item.id === stockItem.id &&
          lineItem.scannedQuantity < lineItem.unitCount &&
          !isDeclined(document.id, lineItem)
        ) {
          documentId = document.id;
        }

        if (documentId) {
          break documentLoop;
        }
      }
    }

    if (!documentId) {
      playErrorSound('item');
      dispatch(showGlobalProgressModal(false));
      return displayErrorNotification(ERROR_MESSAGE_NOT_FOUND_ITEM);
    }

    await createOrUpdateTask(stockItem, containerCode, businessUnit!.id, 1, 'request', documentId);
    const success = await RequestStockTransferHelper.updateScannedQuantityFromTasks(
      businessUnit!.id,
      documentId,
      stockItem.id
    );
    // setLatestScannedTask({
    //   itemId: stockItem.id,
    //   destinationBusinessUnitId: businessUnit.id,
    //   containerCode: container,
    //   status: taskStatus,
    // });
    notifyServiceWorker();

    if (!success) {
      playErrorSound('item');
      displayErrorNotification(ERROR_MESSAGE_NOT_FOUND_ITEM);
    } else {
      playSuccessSound('item');
    }

    dispatch(showGlobalProgressModal(false));
  };

  const handleScan = useCallback(() => {
    if (scannedBarcode.match(AppConfig.getConfig()?.itemInventory?.containerBarcode || /^C/)) {
      setFormError((prevState) => ({ ...prevState, containerMsg: '' }));

      setContainerCode(scannedBarcode);
      playSuccessSound('container');
    } else {
      handleItemScan(scannedBarcode);
    }

    setScannedBarcode('');
  }, [scannedBarcode]);

  const handleContainerSeal = useCallback(async () => {
    if (!containerCode) {
      return dispatch(
        setSnack({
          text: 'Es muss ein Container angegeben sein',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );
    }

    dispatch(showGlobalProgressModal(true));

    try {
      const tasksToProcess = await getTasks(containerCode, businessUnit!.id, [
        StockTransferStatus.READY,
        StockTransferStatus.SENDING,
      ]);

      if (tasksToProcess.length) {
        setIsConfirmationDialogOpen(false);
        dispatch(showGlobalProgressModal(false));
        return dispatch(
          setSnack({
            text: 'Es werden noch Artikel zu diesem Container im Hintergrund bearbeitet. Bitte versuchen Sie es gleich erneut.',
            severity: 'warning',
            autoHideDuration: 5000,
          })
        );
      }
      await createSealedContainer(containerCode, businessUnit!.id, 'request');
      setContainerCode('');
      await dispatch(triggerBackgroundSync('stockTransferSealContainerTasks', true));
    } catch (error) {
      dispatch(
        setSnack({
          text: 'Es ist ein Fehler aufgetreten',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );

      console.error(error);
    } finally {
      setIsConfirmationDialogOpen(false);
      dispatch(showGlobalProgressModal(false));
    }
  }, [containerCode, requestDocuments]);

  const handleQuantityChange = async (
    id: string | undefined,
    documentId: string,
    lineItem: RequestTransferDocumentLineItemTask,
    containerBarcode: string,
    quantity = 1,
    override = false
  ) => {
    if (!containerBarcode) {
      return dispatch(
        setSnack({
          text: 'Es muss ein Container angegeben sein',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );
    }
    const newQuantity = override ? quantity : lineItem.scannedQuantity + quantity;
    const diffQuantity = quantity - ((override && lineItem.scannedQuantity) || 0);
    if (newQuantity < 0) {
      return dispatch(
        setSnack({
          text: 'Die Bestände können nicht unter 0 fallen.',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );
    } else if (newQuantity > lineItem.unitCount) {
      return dispatch(
        setSnack({
          text: 'Das überschreitet die angeforderte Menge.',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );
    }

    try {
      dispatch(showGlobalProgressModal(true));
      if (!id) {
        id = await createOrUpdateTask(
          lineItem.item,
          containerBarcode,
          businessUnit!.id,
          diffQuantity,
          'request',
          documentId
        );
      } else {
        if (!(await modifyTask(id, lineItem.item, diffQuantity))) {
          // Kleiner Hack: Während Daten Übertragen werden, kann die Menge nicht geändert werden.
          await new Promise((r) => setTimeout(r, 750));
          if (!(await modifyTask(id, lineItem.item, diffQuantity))) {
            displayErrorNotification('Menge konnte nicht geändert werden. Bitte erneut probieren.');
            return false;
          }
        }
      }

      await RequestStockTransferHelper.updateScannedQuantityFromTasks(businessUnit!.id, documentId, lineItem.item.id);
      notifyServiceWorker();
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showGlobalProgressModal(false));
    }
  };
  const declineRequest = async (documentId: string, lineItem: RequestTransferDocumentLineItemInterface) => {
    try {
      dispatch(showGlobalProgressModal(true));
      await createOrUpdateTask(
        lineItem.item,
        '', // containerCode,
        businessUnit!.id,
        0,
        'declineRequest',
        documentId
      );
      notifyServiceWorker();
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showGlobalProgressModal(false));
    }
  };

  return (
    <Container>
      <Paper className={defaultClasses.paper}>
        {requestDocuments ? (
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Filiale"
                  disabled
                  value={`${businessUnit?.number} - ${businessUnit?.name}`}
                  data-cy="stock-transfer-form-destination-business-unit-input"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Barcode scannen oder eingeben"
                  variant="outlined"
                  inputRef={scannerInputRef}
                  value={scannedBarcode}
                  onChange={(event) => setScannedBarcode(event.target.value)}
                  error={!!formErrorState.containerMsg}
                  helperText={containerCode ? `Container: ${containerCode}` : formErrorState.containerMsg}
                  onKeyDown={(event) => {
                    if ('Enter' === event.key || '13' === event.code) {
                      event.preventDefault();
                      handleScan();
                    }
                  }}
                  data-cy="stock-transfer-form-scan-input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Suche starten" onClick={() => handleScan} edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!containerCode}
                  onClick={() => setIsConfirmationDialogOpen(!isConfirmationDialogOpen)}
                  fullWidth
                >
                  Container Abschließen
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell size="small" component="th">
                          Artikel
                        </TableCell>
                        <TableCell component="th" align="right">
                          Menge Gescannt / Soll
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sealedContainerTasks.map((task, i) => (
                        <StockTransferItemRow
                          task={task}
                          key={`sealed-container-${i}`}
                          onRowClick={(event) => {
                            if (StockTransferStatus.CONTAINER_SEALING_ERROR === task.status) {
                              setContextMenu({
                                anchorEl: event.target,
                                documentId: '',
                                taskType: 'ContainerSealTask',
                                selectedTask: task,
                              });
                            }
                          }}
                        />
                      ))}
                      {requestDocuments.map((document, i) => {
                        return document.lineItems.map((lineItem, j) => (
                          <RequestStockTransferItemRow
                            key={`${i}_${j}`}
                            document={document}
                            lineItem={lineItem}
                            declined={isDeclined(document.id, lineItem)}
                            setContextMenu={setContextMenu}
                          />
                        ));
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {contextMenu && !isDeclined(contextMenu.documentId, contextMenu.lineItem!) && (
              <ItemRowContextMenu
                open
                onClose={() => setContextMenu(null)}
                anchorEl={contextMenu.anchorEl}
                {...('TransferTask' === contextMenu.taskType &&
                  contextMenu.lineItem && {
                    onReduction: async () => {
                      await handleQuantityChange(
                        undefined,
                        contextMenu!.documentId,
                        contextMenu!.lineItem!,
                        containerCode,
                        -1
                      );
                      setContextMenu(null);
                    },
                    onQuantityEdit: () => {
                      setQuantityDialog({
                        documentId: contextMenu!.documentId,
                        lineItem: contextMenu!.lineItem!,
                        containerCode: contextMenu!.containerCode || containerCode,
                      });
                      setContextMenu(null);
                    },
                    onDecline:
                      contextMenu.lineItem.scannedQuantity < contextMenu.lineItem.unitCount
                        ? async () => {
                            await declineRequest(contextMenu!.documentId, contextMenu!.lineItem!);
                            setContextMenu(null);
                          }
                        : undefined,
                  })}
                {...('ItemTask' === contextMenu.taskType &&
                  contextMenu.lineItem && {
                    onReduction:
                      (contextMenu.lineItem.scannedQuantity || 0) > 0
                        ? async () => {
                            await handleQuantityChange(
                              contextMenu!.selectedTask?.id,
                              contextMenu!.documentId,
                              contextMenu!.lineItem!,
                              contextMenu!.containerCode || containerCode,
                              -1
                            );
                            setContextMenu(null);
                          }
                        : undefined,
                    onQuantityEdit:
                      (contextMenu.lineItem.scannedQuantity || 0) > 0
                        ? () => {
                            setQuantityDialog({
                              id: contextMenu!.selectedTask?.id,
                              documentId: contextMenu!.documentId,
                              lineItem: contextMenu!.lineItem!,
                              containerCode: contextMenu!.containerCode || containerCode,
                            });
                            setContextMenu(null);
                          }
                        : undefined,
                    onRemoveError:
                      (contextMenu.lineItem.scannedQuantity || 0) <= 0 &&
                      contextMenu!.selectedTask?.status === StockTransferStatus.ERROR
                        ? async () => {
                            await removeTask(
                              { id: contextMenu.selectedTask!.id },
                              'request',
                              StockTransferStatus.ERROR
                            );
                            await RequestStockTransferHelper.updateScannedQuantityFromTasks(
                              businessUnit!.id,
                              contextMenu.documentId,
                              contextMenu.lineItem!.item.id
                            );
                            setContextMenu(null);
                          }
                        : undefined,
                  })}
                {...('ContainerSealTask' === contextMenu.taskType &&
                  contextMenu.selectedTask && {
                    onRemoveError: async () => {
                      await removeTask(
                        { id: contextMenu.selectedTask!.id },
                        'request',
                        StockTransferStatus.CONTAINER_SEALING_ERROR
                      );
                      setContextMenu(null);
                    },
                  })}
              />
            )}
            {quantityDialog && (
              <RequestStockTransferItemQuantityDialog
                onClose={() => setQuantityDialog(null)}
                onSave={async (newQuantity) => {
                  await handleQuantityChange(
                    quantityDialog?.id,
                    quantityDialog!.documentId,
                    quantityDialog!.lineItem,
                    quantityDialog!.containerCode || containerCode,
                    newQuantity,
                    true
                  );
                  setQuantityDialog(null);
                }}
                lineItem={quantityDialog.lineItem}
              />
            )}
            {isConfirmationDialogOpen && (
              <Dialog
                open={isConfirmationDialogOpen}
                onClose={() => setIsConfirmationDialogOpen(false)}
                data-cy="free-stock-transfer-form-confirm-dialog"
              >
                <DialogTitle>Achtung</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Der Inhalt des Containers kann nach dem Verplomben nicht mehr verändert werden.
                    <br />
                    Wurde der Container bereits verplombt?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={() => setIsConfirmationDialogOpen(false)} fullWidth>
                    Abbrechen
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleContainerSeal} fullWidth>
                    Bestätigen
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Fragment>
        ) : (
          <Typography color="textSecondary" gutterBottom>
            Wird geladen...
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default RequestStockTransferForm;
