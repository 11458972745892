import { ItemInterface } from '../interfaces/ItemListInterface';
import { DB } from '../modules/app/client/dataProvider/storageClient';
import AppConfig from '../modules/app/config';

export const itemDisplayNumber = (
  item: Partial<ItemInterface>,
  forceDisplayQualifier?: string
): { hint: string; label: string; value?: string } => {
  const displayField =
    forceDisplayQualifier || (AppConfig.getConfig().item?.displayNumber?.defaultQualifier ?? 'itemNo');
  if (displayField === 'gtin') {
    const gtins = (item.posIdentities || []).filter((i) => i.posItemIdQualifier === 'gtin');
    gtins.sort((a, b) => (a.index || 0) - (b.index || 0));
    if (gtins.length > 0) {
      return {
        hint: 'gtin',
        label: 'EAN',
        value: gtins[0].posItemId,
      };
    }
    if (forceDisplayQualifier) {
      return {
        hint: 'gtin',
        label: 'EAN',
        value: undefined,
      };
    }
  }
  if (displayField === 'customItemNo') {
    return {
      hint: 'customItemNo',
      label: AppConfig.getConfig().item?.customItemNo?.label ?? 'Artikel-Nr',
      value: item.customItemNo,
    };
  }

  return {
    hint: 'itemNo',
    label: 'Artikel-Nr',
    value: item.itemNo,
  };
};

export const codeToSearchList = (code: string): string[] => {
  if (!code) {
    return [];
  }
  const codes = [code];
  const { itemNumberDigitLength = null, itemNumberDigitOffset = 0 } = AppConfig.getConfig().item?.customItemNo ?? {};

  if (code.length === 12) {
    // got a UPC, search EAN13 / GTIN
    codes.push('0' + code);
  } else if (code.length === 13 && code.substring(0, 1) === '0') {
    // got EAN13 / GTIN with first digit 0, search UPC
    codes.push(code.substring(1));
  }

  if (itemNumberDigitLength) {
    codes.push(code.slice(itemNumberDigitOffset, itemNumberDigitLength));
  }

  return codes;
};

export const findItemByCode = async (code: string) => {
  const codes = codeToSearchList(code);
  if (codes.length === 0) {
    // Prevent using item with empty EAN as default
    return undefined;
  }

  if (AppConfig.getConfig().item?.findItemByCode?.ignoreItemNo) {
    return DB.items.where('scanCodes').anyOf(codes).or('customItemNo').anyOf(codes).first();
  } else {
    return DB.items.where('itemNo').anyOf(codes).or('scanCodes').anyOf(codes).or('customItemNo').anyOf(codes).first();
  }
};

export const findItemById = async (id: string): Promise<ItemInterface | undefined> =>
  DB.items.where('id').equals(id).first();
