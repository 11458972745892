import {
  AppBar,
  Container,
  Dialog,
  IconButton,
  LinearProgress,
  Slide,
  Theme,
  Toolbar,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ItemInterface } from '../../../interfaces/ItemListInterface';
import { getOnlineStatus } from '../../../redux/interface/selectors';
import { getCurrentSession } from '../../../redux/security/selectors';
import GraphQLClient from '../client/dataProvider/apiClient';

const CURRENT_STOCK_QUERY = gql`
  query ($businessUnitNumber: Int!, $articleNumber: String!, $withRadiusSearch: Float) {
    currentStockMetric(
      businessUnitNumber: $businessUnitNumber
      articleNumber: $articleNumber
      withRadiusSearch: $withRadiusSearch
    ) {
      radius {
        businessUnit {
          number
          name
          statusCode
        }
        stock
        distance
      }
    }
  }
`;

interface StockResult {
  businessUnit: {
    number: number;
    name: string;
    statusCode: string;
  };
  stock: number;
  distance: number;
}

interface ExploreStockDialogProps extends WithStyles<typeof styles> {
  item: ItemInterface;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    container: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    itemName: {
      fontSize: theme.typography.pxToRem(22),
      marginBottom: theme.spacing(1),
    },
  });

const LoadingIndicator = () => {
  return (
    <Alert severity="info">
      Die Echtzeit-Bestände in der Umgebung werden abgerufen, bitte einen Augenblick Geduld...
      <LinearProgress style={{ marginTop: '1rem' }} />
    </Alert>
  );
};
const NoResults = () => {
  return (
    <Alert severity="warning" data-cy="no-results">
      Es wurden keine Bestände im Umkreis von 20 km gefunden.
    </Alert>
  );
};
const ErrorOccurred = () => {
  return (
    <Alert severity="error" data-cy="request-error">
      Es ist ein Fehler bei der Abfrage aufgetreten. Stellen Sie sicher das Internetempfang vorhanden ist oder versuchen
      Sie es später erneut.
    </Alert>
  );
};
const ResultList = ({ results }: { results: StockResult[] }) => {
  return (
    <>
      <Table data-cy="explore-stock-dialog-results" size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 600 }}>Filiale</TableCell>
            <TableCell style={{ fontWeight: 600, textAlign: 'right' }}>Bestand</TableCell>
            <TableCell style={{ fontWeight: 600, textAlign: 'right' }}>Distanz</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((result, key) => (
            <TableRow key={key}>
              <TableCell>{result.businessUnit.name}</TableCell>
              <TableCell style={{ textAlign: 'right' }}>{result.stock}</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                {result.distance.toFixed(2)} km
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const ExploreStockDialog = ({ item, onClose, classes }: ExploreStockDialogProps) => {
  const isOnline = useSelector(getOnlineStatus);
  const session = useSelector(getCurrentSession);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [results, setResults] = useState<StockResult[]>([]);

  useEffect(() => {
    setLoading(true);
    setResults([]);

    if (!session) {
      setLoading(false);
      return;
    }

    (async () => {
      const client = await GraphQLClient();
      try {
        const results = await client.query({
          fetchPolicy: 'no-cache',
          query: CURRENT_STOCK_QUERY,
          variables: {
            businessUnitNumber: session.businessUnitGroup.unit.number,
            articleNumber: item.itemNo,
            withRadiusSearch: 20,
          },
        });

        setResults(
          (results.data.currentStockMetric?.radius ?? [])
            // eigene Filiale ausfiltern
            .filter((result: StockResult) => result.businessUnit.number !== session.businessUnitGroup.unit.number)
            // Null-Bestände ausfiltern
            .filter((result: StockResult) => result.stock > 0)
            // nur aktive Filialen berücksichtigen
            .filter((result: StockResult) => result.businessUnit.statusCode === 'active')
        );
        setHasError(false);
      } catch (e: unknown) {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [item, session, setLoading, setResults, setHasError]);

  return (
    <Dialog fullScreen open={true} onClose={onClose} TransitionComponent={Transition} data-cy="explore-stock-dialog">
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title} data-cy="appBarTitle">
            Bestandsabfrage
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Typography variant="h6">{item.description}</Typography>
        {!isOnline && (
          <Alert severity="error" data-cy="no-internet-connection">
            Die Bestandsabfrage ist nur möglich bei Internetempfang
          </Alert>
        )}
        {isOnline && isLoading && <LoadingIndicator />}
        {isOnline && !isLoading && hasError && <ErrorOccurred />}
        {isOnline && !isLoading && !hasError && results.length === 0 && <NoResults />}
        {isOnline && !isLoading && !hasError && results.length > 0 && <ResultList results={results} />}
      </Container>
    </Dialog>
  );
};

export default withStyles(styles)(ExploreStockDialog);
