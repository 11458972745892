import { App, Module } from '@meeva/service-client-core/interfaces/AppInterface';
import { CategoryChildInterface } from '@meeva/service-client-core/src/modules/app/layout/navigator';
import { get } from 'lodash';
import { ReactElement } from 'react';
import { Route } from 'react-router';

import distributionPackagingSlipNavigations from './modules/distributionPackagingSlip/navigations';
import distributionPackagingSlipPages from './modules/distributionPackagingSlip/pages';
import freeStockTransferNavigations from './modules/freeStockTransfer/navigations';
import freeStockTransferPages from './modules/freeStockTransfer/pages';
import incomingTransferNavigations from './modules/incomingTransfer/navigations';
import incomingTransferPages from './modules/incomingTransfer/pages';
import missingItemAnalysisNavigations from './modules/missingItemAnalysis/navigations';
import missingItemAnalysisPages from './modules/missingItemAnalysis/pages';
import requestStockTransferNavigations from './modules/requestStockTransfer/navigations';
import requestStockTransferPages from './modules/requestStockTransfer/pages';
import stockTakeNavigations from './modules/stockTake/navigations';
import stockTakePages from './modules/stockTake/pages';
import { checkOpenShelfTasks } from './redux/modules/stockTake/operations';
import reducers from './redux/rootReducer';

const moduleDeclaration = [
  // Inventur
  {
    navigations: stockTakeNavigations,
    pages: stockTakePages,
    serviceWorkers: ['InventoryTasksLoader', 'StockTakeResultsProcessor'],
    defaultEnabled: true,
    enabledStateConfigPath: 'stockTake.enable',
  },
  // Lieferung / Filail-Verteilung
  {
    navigations: distributionPackagingSlipNavigations,
    pages: distributionPackagingSlipPages,
    serviceWorkers: ['DistributionPackagingSlipLoader', 'DistributionPackagingSlipsQueueTasksProcessor'],
    defaultEnabled: false,
    enabledStateConfigPath: 'packagingSlip.storeDistribution.enable',
  },
  // "Freier" Filialtausch
  {
    navigations: freeStockTransferNavigations,
    pages: freeStockTransferPages,
    serviceWorkers: ['StockTransferTasksProcessor', 'StockTransferSealContainerProcessor'],
    defaultEnabled: false,
    enabledStateConfigPath: 'stockTransfer.enable',
  },
  // "Packen / Packliste" Filialtausch
  {
    navigations: requestStockTransferNavigations,
    pages: requestStockTransferPages,
    serviceWorkers: ['RequestTransferDocumentsLoader'],
    defaultEnabled: false,
    enabledStateConfigPath: 'stockTransfer.enable',
  },
  // "Annahme" Filialtausch
  {
    navigations: incomingTransferNavigations,
    pages: incomingTransferPages,
    serviceWorkers: ['IncomingTransfersLoader', 'IncomingTransfersProcessor'],
    defaultEnabled: false,
    enabledStateConfigPath: 'stockTransfer.enable',
  },
  // Fehlartikelanalyse
  {
    navigations: missingItemAnalysisNavigations,
    pages: missingItemAnalysisPages,
    serviceWorkers: [],
    defaultEnabled: false,
    enabledStateConfigPath: 'missingItemAnalysis.enable',
  },
];

class ItemInventoryModule implements Module {
  app!: App;

  init(app: App) {
    this.app = app;

    const navigation: CategoryChildInterface[] = [];
    const pages: ReactElement[] = [];
    const serviceWorkers: string[] = [];

    //TODO: check businessUnit type
    moduleDeclaration.forEach((module) => {
      const isEnabled = get(app.getConfig(), module.enabledStateConfigPath || '', module.defaultEnabled);

      if (isEnabled) {
        navigation.push(...module.navigations);
        pages.push(...module.pages);
        serviceWorkers.push(...module.serviceWorkers);
      }
    });

    app.addReducers(reducers);
    app.addRoutes(pages as unknown as Route[]);
    app.addNavigations(navigation);
    app.addServiceWorkers(serviceWorkers);
    app.registerEventListener('pre_logout', checkOpenShelfTasks);
  }
}

export default new ItemInventoryModule();
