import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import DatabaseWorker from 'worker-loader?filename=database.js!../../webworker/DatabaseWorker';

import { getDatabaseMessageQueue } from '../../redux/interface/selectors';

export interface DatabaseWorkerWrapperProps {
  messages: ReturnType<typeof getDatabaseMessageQueue>;
}

let databaseWorker: DatabaseWorker | undefined;
const DatabaseWorkerWrapper = (props: DatabaseWorkerWrapperProps) => {
  const { messages } = props;

  const listeners: Map<string, (result: any) => void> = new Map();

  useEffect(() => {
    databaseWorker = new Worker(new URL('../../webworker/DatabaseWorker', import.meta.url));

    return () => {
      if (databaseWorker) {
        databaseWorker.terminate();
        databaseWorker = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (!databaseWorker) {
      return;
    }

    let message:
      | (ReturnType<typeof getDatabaseMessageQueue> extends Array<infer U>
          ? U
          : ReturnType<typeof getDatabaseMessageQueue>)
      | undefined;
    while ((message = messages.shift()) !== undefined) {
      if (message.callback) {
        listeners.set(message.responseId, message.callback);
      }

      const { callback, ...channelMessage } = message;

      databaseWorker.postMessage(channelMessage);
    }
  }, [messages, listeners]);

  const messageHandler = useCallback(
    (event: MessageEvent) => {
      const listener = listeners.get(event.data.responseId);
      if (listener) {
        listener(event.data.result);
        listeners.delete(event.data.responseId);
      }
    },
    [listeners]
  );

  useEffect(() => {
    databaseWorker && databaseWorker.addEventListener('message', messageHandler);

    return () => {
      databaseWorker && databaseWorker.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  return null;
};

export default connect((state) => ({
  messages: getDatabaseMessageQueue(state),
}))(DatabaseWorkerWrapper);
