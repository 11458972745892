import React from 'react';
import { Route, Switch } from 'react-router';

import FreeStockTransferForm from './components/FreeStockTransferForm';

const FreeStockTransferRouter = () => (
  <Switch>
    <Route component={FreeStockTransferForm} />
  </Switch>
);

export default FreeStockTransferRouter;
