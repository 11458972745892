import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { ReducersMapObject } from 'redux';
import thunk from 'redux-thunk';

import createRootReducer from './rootReducer';

export type ReplaceReturnType<T extends (...a: any) => any, TNewReturn> = (...a: Parameters<T>) => TNewReturn;

export const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const rootReducer = (moduleReducers: ReducersMapObject) => createRootReducer(history, moduleReducers);

export type RootState = ReturnType<typeof rootReducer>;

export const createStore = (moduleReducers: ReducersMapObject) => {
  return configureStore({
    reducer: rootReducer(moduleReducers),
    middleware: [thunk, routerMiddleware(history)],
    enhancers: [sentryReduxEnhancer],
    devTools: true, // TODO Ersetzt mit ENV oder YML oder config.json ?!
  });
};
