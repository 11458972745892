import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, WithStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import Assignment from '@material-ui/icons/Assignment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ExploreIcon from '@material-ui/icons/Explore';
import ImageIcon from '@material-ui/icons/Image';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';

import { ItemInventoryMetricInterface } from '../../../interfaces/ItemInventoryMetricInterface';
import { ItemInterface } from '../../../interfaces/ItemListInterface';
import { getConfig } from '../../../redux/common/app/selectors';
import { setTitle } from '../../../redux/interface/actions';
import { setItemToProcess } from '../../../redux/items/detail/actions';
import { getItems } from '../../../redux/items/list/operations';
import { getLatestJournalEntriesByItemId } from '../../../utils/itemInventoryMetricHelper';
import { addItemLabel, createItemLabelsCollection } from '../../../utils/itemLabelsCollectionHelper';
import { findItemByCode as helperFindItemByCode, findItemById } from '../../../utils/items';
import { DetailedCurrencyField } from '../../common/components/Currency';
import ItemImageDialog from '../../common/components/ItemImageDialog';
import { DB } from '../client/dataProvider/storageClient';
import AppConfig from '../config';
import ExploreStockDialog from './ExploreStockDialog';
import ScanInput from './ScanInput';

export const procedureTypeMap = new Set<{ name: string; label: string }>([
  {
    name: 'breakageContainer',
    label: 'Containerbruch',
  },
  {
    name: 'breakageSupplier',
    label: 'Lieferantenbruch',
  },
  {
    name: 'breakageTransportation',
    label: 'Fuhrenbruch',
  },
  {
    name: 'claim',
    label: 'Reklamation',
  },
  {
    name: 'ownNeeds',
    label: 'Eigenbedarf',
  },
  {
    name: 'samplingPurchase',
    label: 'Musterentnahme Einkauf',
  },
  {
    name: 'merchandiseTraffic_houseToHouse',
    label: 'Warenverkehr vHzH',
  },
  {
    name: 'merchandiseTraffic_houseToHouseCorrection',
    label: 'Korr. Warenverkehr vHzH',
  },
  {
    name: 'merchandiseTraffic_direct',
    label: 'Warenverkehr direkt',
  },
  {
    name: 'merchandiseTraffic_webShop',
    label: 'Transit Webshop',
  },
  {
    name: 'merchandiseTraffic_webShopCorrection',
    label: 'Korr. Transit Webshop',
  },
  {
    name: 'routeInput',
    label: 'Strecke Retoure',
  },
  {
    name: 'recall',
    label: 'Rückruf',
  },
]);

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    itemName: {
      paddingBottom: theme.spacing(1),
    },
    updateDate: {
      color: theme.palette.grey.A100,
      textAlign: 'right',
      fontSize: 13,
    },
  });

interface CommonProps {
  displayPrices: boolean;
  displayStock: boolean;
  displayLatestMovements: boolean;
  enableLabelPrint: boolean;
  enablePageReturn: boolean;
  allowStartItemInventory: boolean;
  allowStartStockMovement: boolean;
}

interface PathParamType {
  id: string | undefined;
  code: string | undefined;
  previousCode: string | undefined;
}

interface CollectionUpdateInfo {
  [name: string]: Date;
}

const UpdateDate = connect()(
  withStyles(styles)(({ date, classes }: any) => {
    if (!date) {
      return null;
    }

    return (
      <div className={classes.updateDate}>
        Stand:{' '}
        {new Intl.DateTimeFormat('de-DE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }).format(date)}
      </div>
    );
  })
);

interface ItemDetailsProps extends WithStyles, CommonProps {
  config: any;
  updateInfo: CollectionUpdateInfo;
  searching: boolean;
  item: ItemInterface | undefined;
  relatedSeriesItem: ItemInterface | undefined;
  previousItemCode: string | undefined;
  push: typeof push;
  setTitle: typeof setTitle;
}

const ItemJournalField = ({
  label,
  type,
  journalEntry,
}: {
  label: string;
  type: string;
  journalEntry: ItemInventoryMetricInterface | undefined | null;
}) => {
  const getTranslatedProcedureType = (type: string) => {
    const procedureType = Array.from(procedureTypeMap).filter((procedure) => procedure.name === type)[0] || null;

    return procedureType ? procedureType.label : `Unbekannter Typ (${type})`;
  };

  return (
    <dl>
      <dt>{label}</dt>
      <dd data-cy={`latest-${type}`}>
        {journalEntry && journalEntry.date ? (
          <table>
            <tbody>
              <tr>
                <td>Datum:</td>
                <td>
                  {new Intl.DateTimeFormat('de-DE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  }).format(journalEntry.date)}
                </td>
              </tr>
              <tr>
                <td>{'physical-count' === type ? 'Differenz' : 'Menge'}:</td>
                <td>{journalEntry.value}</td>
              </tr>
              {journalEntry.procedureType && (
                <tr>
                  <td>Typ:</td>
                  <td>{getTranslatedProcedureType(journalEntry.procedureType)}</td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div>Noch nie</div>
        )}
      </dd>
    </dl>
  );
};

const ItemDetails = ({
  config,
  searching,
  item,
  relatedSeriesItem,
  previousItemCode,
  classes,
  push,
  updateInfo,
  setTitle,
  displayPrices,
  displayStock,
  displayLatestMovements,
  enableLabelPrint,
  enablePageReturn,
  allowStartItemInventory,
  allowStartStockMovement,
}: ItemDetailsProps) => {
  useEffect(() => {
    setTitle(`Artikelinfo`);
  }, [setTitle]);
  const [isItemImageDialogOpen, setIsItemImageDialogOpen] = React.useState<boolean>(false);
  const [isExploreStockDialogOpen, setExploreStockDialogOpen] = React.useState<boolean>(false);
  const [latestJournalEntries, setLatestJournalEntries] = React.useState<ItemInventoryMetricInterface[]>([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const defaultPriceConfig = { display: false, showValidityPeriod: false };
  const {
    retailPrice: retailPriceConfig = defaultPriceConfig,
    previousRetailPrice: previousRetailPriceConfig = defaultPriceConfig,
    permanentPrice: permanentPriceConfig = defaultPriceConfig,
    msrp: msrpConfig = defaultPriceConfig,
  } = config.item?.details?.prices || [];

  useEffect(() => {
    if (!item) {
      return;
    }

    (async () => {
      const _latestJournalEntries = await getLatestJournalEntriesByItemId(item.id);

      setLatestJournalEntries(_latestJournalEntries);
    })();
  }, [item]);

  const exploreStock = useCallback(() => {
    setExploreStockDialogOpen(true);
  }, [setExploreStockDialogOpen]);

  const closeExploreStockDialog = useCallback(() => {
    setExploreStockDialogOpen(false);
  }, [setExploreStockDialogOpen]);

  if (searching) {
    return (
      <Container>
        <Skeleton variant="rect" height={50} />
        <Box pt={1}>
          <Skeleton variant="rect" height={150} />
        </Box>
        <Box pt={1}>
          <Skeleton variant="rect" height={150} />
        </Box>
      </Container>
    );
  }

  if (!item) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Nicht gefunden</AlertTitle>
          Es konnte kein Artikel mit der gesuchten Nummer/Code gefunden werden.
        </Alert>
      </Container>
    );
  }

  const addToLabelCollector = async () => {
    const id = await createItemLabelsCollection();
    await addItemLabel(id, item.itemNo);
    push(`/items/labels-collector/${id}`);
  };

  const startFreeInventoryWithItem = async () => {
    dispatch(
      setItemToProcess({
        item: item.itemNo,
        target: 'item-inventory',
      })
    );
    push(`/itemInventory/type/free`);
  };

  const startStockMovementWithItem = async () => {
    dispatch(
      setItemToProcess({
        item: item.itemNo,
        target: 'stock-movement',
      })
    );
    push(`/items/stock-movement`);
  };

  const gtins = item.posIdentities.filter((posIdentity) => posIdentity.posItemIdQualifier === 'gtin');
  gtins.sort((a, b) => (a.index || 0) - (b.index || 0));
  const otherScanCodes = item.posIdentities.filter((posIdentity) => posIdentity.posItemIdQualifier !== 'gtin');
  otherScanCodes.sort((a, b) => (a.index || 0) - (b.index || 0));

  return (
    <Container>
      <Box mt={1}>
        <Paper style={{ padding: '5px' }}>
          <ScanInput />
        </Paper>
      </Box>
      {previousItemCode && (
        <Box mt={1}>
          <Paper>
            <List component="nav" aria-label="main mailbox folders" data-cy="start-inventory-button">
              <ListItem button onClick={() => push(`/items/details/byCode/${previousItemCode}`)}>
                <ListItemIcon>
                  <BackIcon />
                </ListItemIcon>
                <ListItemText primary="Zurück zum vorherigen Artikel" />
              </ListItem>
            </List>
          </Paper>
        </Box>
      )}

      <Box mt={1}>
        <Card className={classes.paper}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {item.description}
            </Typography>
            <dl data-cy="article-number">
              <dt>Artikelnummer</dt>
              {!config.item?.customItemNo?.hideItemNo && <dd>{item.itemNo}</dd>}
              {config.item?.customItemNo?.show && <dd>{item.customItemNo}</dd>}
            </dl>

            <dl>
              <dt>EAN</dt>
              <dd>
                {gtins.map(({ posItemId }, i) => (
                  <div key={i}>{posItemId}</div>
                ))}
              </dd>
            </dl>

            {otherScanCodes.length > 0 && (
              <dl>
                <dt>Weitere Barcodes</dt>
                <dd>
                  {otherScanCodes.map(({ posItemId }, i) => (
                    <div key={i}>{posItemId}</div>
                  ))}
                </dd>
              </dl>
            )}

            {item.statusTypes && item.statusTypes.length > 0 && (
              <dl>
                <dt>Status (Typ)</dt>
                {item.statusTypes.map(({ name }, key) => (
                  <dd key={key}>{name}</dd>
                ))}
              </dl>
            )}

            {relatedSeriesItem && (
              <React.Fragment>
                <dl>
                  <dt>Folgeartikel</dt>
                  <dd>
                    <Button
                      fullWidth
                      variant="text"
                      endIcon={<OpenInNewIcon />}
                      onClick={() => push(`/items/details/byCode/${relatedSeriesItem.itemNo}/${item.itemNo}`)}
                      style={{
                        justifyContent: 'left',
                        paddingLeft: 0,
                        textTransform: 'none',
                        textAlign: 'left',
                      }}
                    >
                      {relatedSeriesItem.itemNo} <br />
                      {relatedSeriesItem.description}
                    </Button>
                  </dd>
                </dl>
              </React.Fragment>
            )}

            {item.media?.imagePath && (
              <React.Fragment>
                <dl>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    data-cy="item-details-item-image-button"
                    endIcon={<ImageIcon />}
                    onClick={() => setIsItemImageDialogOpen((prevState) => !prevState)}
                  >
                    Produktbild anzeigen
                  </Button>
                </dl>
                <ItemImageDialog
                  open={isItemImageDialogOpen}
                  onClose={() => setIsItemImageDialogOpen(false)}
                  item={item}
                />
              </React.Fragment>
            )}

            <UpdateDate date={updateInfo.items} />
          </CardContent>
        </Card>
      </Box>

      {displayStock && (
        <Box mt={1}>
          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Bestand
              </Typography>
              <dl>
                <dt>Aktueller Bestand</dt>
                <dd>{item.stockQty}</dd>
              </dl>
              <dl>
                <dt>Mindestbestand</dt>
                <dd>
                  {null !== item.targetStockQty
                    ? item.targetStockQty !== item.minTargetStockQty
                      ? `${item.targetStockQty} (statt ${item.minTargetStockQty})`
                      : item.targetStockQty
                    : item.minTargetStockQty}
                </dd>
              </dl>
              <dl>
                <dt>Stückzahl der VE</dt>
                <dd>{item.saleUnitPerPackUnitCount}</dd>
              </dl>
              <UpdateDate date={updateInfo.itemStock} />
            </CardContent>
          </Card>
        </Box>
      )}

      {displayPrices && (
        <Box mt={1}>
          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Preise
              </Typography>
              {msrpConfig.display && (
                <dl>
                  <dt>UPE</dt>
                  <dd data-cy="msrp-price">
                    <DetailedCurrencyField
                      priceInformation={item.priceInformation?.msrp}
                      showValidityPeriod={msrpConfig.showValidityPeriod}
                    />
                  </dd>
                </dl>
              )}
              {previousRetailPriceConfig.display && (
                <dl>
                  <dt>Letzter gültige Preis (Altpreis):</dt>
                  <dd data-cy="previous-retail-price">
                    <DetailedCurrencyField
                      priceInformation={item.priceInformation?.previousRetail}
                      showValidityPeriod={previousRetailPriceConfig.showValidityPeriod}
                    />
                  </dd>
                </dl>
              )}
              {permanentPriceConfig.display && (
                <dl>
                  <dt>Normalpreis</dt>
                  <dd data-cy="permanent-price">
                    <DetailedCurrencyField
                      priceInformation={item.priceInformation?.permanent}
                      showValidityPeriod={permanentPriceConfig.showValidityPeriod}
                    />
                  </dd>
                </dl>
              )}
              {retailPriceConfig.display && item.priceInformation && item.priceInformation.retail && (
                <dl>
                  <dt>Aktionspreis</dt>
                  <dd data-cy="retail-price">
                    <DetailedCurrencyField
                      priceInformation={item.priceInformation.retail}
                      showValidityPeriod={retailPriceConfig.showValidityPeriod}
                    />
                  </dd>
                </dl>
              )}

              <UpdateDate date={updateInfo.itemPrice} />
            </CardContent>
          </Card>
        </Box>
      )}

      {displayLatestMovements && (
        <Box mt={1}>
          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Letzte Warenbewegungen
              </Typography>
              <ItemJournalField
                label="Wareneingang"
                type="goods-entrance"
                journalEntry={latestJournalEntries.find((entry) => 'latestGoodsEntrance' === entry.type)}
              />
              <ItemJournalField
                label="Inventur"
                type="physical-count"
                journalEntry={latestJournalEntries.find((entry) => 'latestPhysicalCount' === entry.type)}
              />
              <ItemJournalField
                label="Verkauf"
                type="sale"
                journalEntry={latestJournalEntries.find((entry) => 'latestSale' === entry.type)}
              />
              <ItemJournalField
                label="Sonstige Bewegung"
                type="other-movement"
                journalEntry={latestJournalEntries.find((entry) => 'latestOtherMovement' === entry.type)}
              />
              <UpdateDate date={updateInfo.itemInventoryFacts} />
            </CardContent>
          </Card>
        </Box>
      )}

      <Box mt={1}>
        <Paper>
          <List component="nav" aria-label="main mailbox folders" data-cy="explore-stock-button">
            <ListItem button onClick={exploreStock}>
              <ListItemIcon>
                <ExploreIcon />
              </ListItemIcon>
              <ListItemText primary="Bestandsabfrage" />
            </ListItem>
          </List>
        </Paper>
      </Box>

      {isExploreStockDialogOpen && <ExploreStockDialog item={item} onClose={closeExploreStockDialog} />}

      {AppConfig.getNavigationRoutes().includes('/items/labels-collector') && enableLabelPrint && (
        <Box mt={1}>
          <Paper>
            <List component="nav" aria-label="main mailbox folders" data-cy="print-label-button">
              {item.posIdentities.length > 0 && (
                <ListItem button onClick={addToLabelCollector}>
                  <ListItemIcon>
                    <LocalOfferIcon />
                  </ListItemIcon>
                  <ListItemText primary="Etikett drucken" />
                </ListItem>
              )}
            </List>
          </Paper>
        </Box>
      )}
      {AppConfig.getNavigationRoutes().includes('/itemInventory') && allowStartItemInventory && (
        <Box mt={1}>
          <Paper>
            <List component="nav" aria-label="main mailbox folders" data-cy="start-inventory-button">
              <ListItem button onClick={startFreeInventoryWithItem}>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText primary="Freies Scannen starten" />
              </ListItem>
            </List>
          </Paper>
        </Box>
      )}
      {AppConfig.getNavigationRoutes().includes('/items/stock-movement') && allowStartStockMovement && (
        <Box mt={1}>
          <Paper>
            <List component="nav" aria-label="main mailbox folders" data-cy="stock-movement-button">
              <ListItem button onClick={startStockMovementWithItem}>
                <ListItemIcon>
                  <Assignment />
                </ListItemIcon>
                <ListItemText primary="Warenbewegung starten" />
              </ListItem>
            </List>
          </Paper>
        </Box>
      )}

      {enablePageReturn && (
        <Box mt={1}>
          <Paper>
            <List component="nav" aria-label="main mailbox folders">
              {item.posIdentities.length > 0 && (
                <ListItem button onClick={() => history.goBack()}>
                  <ListItemIcon>
                    <BackIcon />
                  </ListItemIcon>
                  <ListItemText primary="Zurück zur Inventur" />
                </ListItem>
              )}
            </List>
          </Paper>
        </Box>
      )}
    </Container>
  );
};

const connectDetails = connect(
  (state) => ({
    config: getConfig(state),
  }),
  {
    push,
    setTitle,
  }
);

const ConnectedItemDetails = connectDetails(withStyles(styles)(ItemDetails));

interface ItemDetailsContainerProps extends RouteComponentProps<PathParamType>, CommonProps {}

const ScanMessage = () => {
  return (
    <Container>
      <Paper style={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h4" align="center">
              Bitte scanne nun einen Artikel
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ScanInput />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const ItemDetailsContainer = (props: ItemDetailsContainerProps) => {
  const {
    match,
    displayPrices = true,
    displayStock = true,
    displayLatestMovements = true,
    enableLabelPrint = true,
    enablePageReturn = false,
    allowStartItemInventory = true,
    allowStartStockMovement = true,
  } = props;
  const dispatch = useDispatch();
  const [searching, setSearching] = React.useState(false);
  const [item, setItem] = React.useState<ItemInterface | undefined>(undefined);
  const [relatedSeriesItem, setRelatedSeriesItem] = React.useState<ItemInterface | undefined>(undefined);
  const [updateInfo, setUpdateInfo] = React.useState<CollectionUpdateInfo>({});

  const { code, previousCode } = match.params;

  useEffect(() => {
    const findItemByCode = async (code: string) => {
      setSearching(true);

      const updateInfos = await DB.configuration
        .where('key')
        .anyOf([
          'sync/items/lastUpdate',
          'sync/itemStock/lastUpdate',
          'sync/itemPrice/lastUpdate',
          'sync/itemInventoryFacts/lastUpdate',
        ])
        .toArray();

      setUpdateInfo(
        Object.fromEntries(
          updateInfos.map((item) => {
            return [item.key.replace(/(^sync\/|\/lastUpdate)/g, ''), new Date(item.value)];
          })
        )
      );

      const item = await helperFindItemByCode(code);

      setRelatedSeriesItem(item?.seriesItemId ? await findItemById(item.seriesItemId) : undefined);
      setSearching(false);
      setItem(item);
    };

    if (code) {
      findItemByCode(code);
    }
  }, [code, setSearching, setItem]);

  if (!code) {
    dispatch(setTitle(`Artikel-Info`));

    return <ScanMessage />;
  }

  return (
    <ConnectedItemDetails
      searching={searching}
      item={item}
      relatedSeriesItem={relatedSeriesItem}
      previousItemCode={previousCode}
      updateInfo={updateInfo}
      displayPrices={displayPrices}
      displayStock={displayStock}
      displayLatestMovements={displayLatestMovements}
      enableLabelPrint={enableLabelPrint}
      enablePageReturn={enablePageReturn}
      allowStartItemInventory={allowStartItemInventory}
      allowStartStockMovement={allowStartStockMovement}
    />
  );
};

export default connect(null, {
  getItems,
  findItemById,
})(withRouter(ItemDetailsContainer));
