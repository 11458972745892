import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput/OutlinedInput';
import { createStyles, WithStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import AppConfig from '@meeva/service-client-core/modules/app/config';
import Numpad, { NumpadMobileKeyboardLayout } from '@meeva/service-client-core/modules/common/components/Numpad';
import ScannerInput from '@meeva/service-client-core/modules/common/components/ScannerInput';
import { getGlobalProgressModal } from '@meeva/service-client-core/redux/interface/selectors';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = OutlinedInputProps &
  WithStyles<typeof styles> & {
    scanEvent: any;
    onChange?: (value: string) => void;
    value?: string | null;
    inputType?: string;
    label?: string;
    cypressTag?: string;
    showNumPad?: boolean;
    allowAllCharacters?: boolean;
  };

const styles = () =>
  createStyles({
    numPadContainer: {
      marginTop: 'auto',
    },
    numPad: {
      maxWidth: '590px',
      marginTop: '5px',
      border: '1px solid #aeaeae',
    },
  });

const ScanInput = ({
  allowAllCharacters,
  classes,
  scanEvent,
  onChange,
  value,
  inputType = 'number',
  label = 'Barcode scannen oder eingeben',
  cypressTag = 'item-inventory-scan-input',
  ...props
}: Props) => {
  const [itemNumber, setItemNumber] = useState<string>(value || '');
  const [numPadFirstSet, setNumPadFirstSet] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const configShowNumPad = AppConfig.getConfig()?.itemInventory?.scanMode.showNumPad;

  let showNumPad = false;
  if (props.showNumPad && configShowNumPad) {
    showNumPad = true;
  }

  const isLoading = useSelector((state) => getGlobalProgressModal(state).visible);

  const handleStateChange = (newValue: string | null) => {
    setItemNumber(newValue || '');

    if (typeof onChange === 'function') {
      onChange(newValue || '');
    }
  };

  const search = () => {
    scanEvent(itemNumber);
    handleStateChange('');
  };

  const handleScannerInputChange = (code: string) => {
    scanEvent(code);

    if (typeof onChange === 'function') {
      onChange(code);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleStateChange(event.target.value as string);
  };

  const handleNumpadChange = useCallback(
    (code: string) => {
      if (code === 'backspace' || code === 'Backspace') {
        if (itemNumber.length > 0) {
          setItemNumber(itemNumber.slice(0, -1));
        }
      } else if (code === 'return' || code === 'Enter') {
        search();
      } else if (allowAllCharacters || !isNaN(Number(code))) {
        if (numPadFirstSet) {
          setItemNumber(code);
        } else {
          setItemNumber(itemNumber + code);
        }
        setNumPadFirstSet(false);
      }

      inputRef.current?.focus();
    },
    [value, onChange, onsubmit, inputRef, itemNumber]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ScannerInput onScan={handleScannerInputChange} disabled={props.disabled} />
        <FormControl fullWidth variant="outlined" data-cy={cypressTag}>
          <InputLabel>Barcode scannen oder eingeben</InputLabel>
          <OutlinedInput
            {...props}
            type={!allowAllCharacters && showNumPad ? undefined : inputType}
            label={label}
            inputRef={inputRef}
            value={itemNumber}
            onChange={handleChange}
            disabled={props.disabled || isLoading}
            onKeyDown={(event) => {
              if ('Enter' === event.key || '13' === event.code) {
                event.preventDefault();
                search();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Suche starten" onClick={() => search()} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            inputProps={{
              ['data-cy']: 'scan-input',
              inputMode: showNumPad ? 'none' : undefined,
            }}
          />
        </FormControl>
        {showNumPad && (
          <Numpad keys={NumpadMobileKeyboardLayout} onClick={handleNumpadChange} className={classes.numPad} />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ScanInput);
