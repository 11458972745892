import { Event } from '../../interfaces/EventInterface';
import * as types from '../../redux/event/types';

export const itemInventory = (state: Array<Event> = [], action: { type: string; payload?: any }) => {
  switch (action.type) {
    case types.SET_ITEM_INVENTORY_EVENTS:
      return [...action.payload];
    default:
      return state;
  }
};
