import { Collection } from 'dexie';
import { v4 as uuidv4 } from 'uuid';

import {
  ItemInterface,
  StockTransferLineItemInterface,
  StockTransferStatus,
  StockTransferTaskInterface,
  StockTransferTaskType,
} from '../interfaces/StockTransferInterface';
import { DB } from '../modules/app/client/dataProvider/storageClient';

const taskPerItem = true;

async function createOrUpdateTask(
  item: ItemInterface,
  containerCode: string,
  destinationBusinessUnitId: string,
  quantity: number,
  type: StockTransferTaskType = 'free',
  requestDocumentId?: string
) {
  const status = StockTransferStatus.READY;
  return DB.transaction('rw', DB.stockTransferTasks, async () => {
    let taskHasLineItems = true;

    const taskSuccess = await DB.stockTransferTasks
      .where({ destinationBusinessUnitId, containerCode })
      .and((task) => task.type === type && task.status === status)
      .and((task) => task.requestDocumentId === requestDocumentId)
      .and((task) => !taskPerItem || task.lineItems.find((li) => li.item.id === item.id) !== undefined)
      .modify((task) => {
        let itemExists = false;

        task.lineItems.forEach((lineItem: StockTransferLineItemInterface, key: number) => {
          if (lineItem.item.id === item.id) {
            lineItem.unitCount += quantity;
            itemExists = true;
          }

          if (!lineItem.unitCount) {
            task.lineItems.splice(key, 1);
          }
        });

        if (!itemExists) {
          task.lineItems.push({
            item,
            unitCount: quantity,
            scannTime: new Date(),
          });
        }

        taskHasLineItems = Boolean(task.lineItems.length);
      });

    if (taskSuccess && !taskHasLineItems) {
      await DB.stockTransferTasks
        .where({ destinationBusinessUnitId, containerCode })
        .and((task) => task.type === type && task.status === status)
        .and((task) => task.requestDocumentId === requestDocumentId)
        .delete();
    }

    if (!taskSuccess) {
      return DB.stockTransferTasks.add({
        id: uuidv4(),
        destinationBusinessUnitId,
        containerCode,
        status,
        createdAt: new Date(),
        type,
        lineItems: [
          {
            item,
            unitCount: quantity,
            scannTime: new Date(),
          },
        ],
        requestDocumentId,
      });
    }
  });
}

export async function modifyTask(id: string, item: ItemInterface, quantity: number) {
  const allowedStatus = [StockTransferStatus.ERROR, StockTransferStatus.READY];

  return DB.transaction('rw', DB.stockTransferTasks, async () => {
    let itemExists = false;
    let taskHasLineItems = true;

    const taskSuccess = await DB.stockTransferTasks
      .where({ id })
      .and((task) => allowedStatus.includes(task.status))
      .modify((task) => {
        task.lineItems.forEach((lineItem: StockTransferLineItemInterface, key) => {
          if (lineItem.item.id === item.id) {
            lineItem.unitCount += quantity;
            itemExists = true;
          }
          if (!lineItem.unitCount) {
            task.lineItems.splice(key, 1);
          }
        });
        taskHasLineItems = Boolean(task.lineItems.length);
      });
    if (taskSuccess && !taskHasLineItems) {
      await DB.stockTransferTasks
        .where({ id })
        .and((task) => allowedStatus.includes(task.status))
        .delete();
    }
    return taskSuccess && itemExists;
  });
}

export async function mergeTasks(
  destinationBusinessUnitId: string,
  containerCode: string,
  status: StockTransferStatus = StockTransferStatus.SENT,
  type: StockTransferTaskType = 'free',
  requestDocumentId?: string
) {
  return DB.transaction('rw', DB.stockTransferTasks, async () => {
    const existingTasks = await DB.stockTransferTasks
      .where({ destinationBusinessUnitId, containerCode })
      .and((task) => status === task.status && type === task.type)
      .and((task) => task.requestDocumentId === requestDocumentId)
      .toArray();

    // Check for duplicates
    if (existingTasks.length > 1) {
      const lineItemMap = new Map<string, StockTransferLineItemInterface>();

      for (const task of existingTasks) {
        const { destinationBusinessUnitId, containerCode, status, lineItems } = task;

        for (const lineItem of lineItems) {
          const key = `${destinationBusinessUnitId}_${containerCode}_${status}_${lineItem.item.id}`;
          const lineItemValue = lineItemMap.get(key);
          const unitCount = lineItemValue?.unitCount || 0;
          let highestScannTime = new Date();

          if (lineItemValue?.scannTime) {
            highestScannTime = lineItemValue?.scannTime;
            if (lineItem.scannTime) {
              highestScannTime = new Date(Math.max(lineItemValue.scannTime.getTime(), lineItem.scannTime.getTime()));
            }
          }

          const newUnitCount = unitCount + lineItem.unitCount;
          lineItemMap.set(key, { unitCount: newUnitCount, item: lineItem.item, scannTime: highestScannTime });
        }
      }

      const lineItems = [...lineItemMap.values()];
      const totalQuantity = lineItems.reduce((val, li) => val + li.unitCount, 0);

      await DB.stockTransferTasks
        .where({ destinationBusinessUnitId, containerCode })
        .and((task) => status === task.status && type === task.type)
        .and((task) => task.requestDocumentId === requestDocumentId)
        .delete();

      if (totalQuantity !== 0.0) {
        await DB.stockTransferTasks.add({
          id: uuidv4(),
          destinationBusinessUnitId,
          containerCode,
          status,
          type,
          requestDocumentId,
          createdAt: existingTasks[0].createdAt,
          updatedAt: new Date(),
          lineItems,
        });
      }
    }
  });
}

async function createSealedContainer(
  containerCode: string,
  destinationBusinessUnitId: string,
  type: StockTransferTaskType = 'free'
) {
  await DB.stockTransferTasks.add({
    id: uuidv4(),
    destinationBusinessUnitId,
    containerCode,
    status: StockTransferStatus.CONTAINER_SEALING,
    createdAt: new Date(),
    type,
    lineItems: [],
  });
}

async function getTasks(
  containerCode?: string | null,
  destinationBusinessUnitId?: string | null,
  status?: StockTransferStatus[] | null,
  type?: string
): Promise<StockTransferTaskInterface[]> {
  let tasks: StockTransferTaskInterface[] = (await DB.stockTransferTasks.toArray()) || [];

  if (containerCode && destinationBusinessUnitId) {
    tasks = (await DB.stockTransferTasks.where({ destinationBusinessUnitId, containerCode }).toArray()) || [];
  }

  if (status) {
    tasks = tasks.filter((task) => status.includes(task.status));
  }
  if (type) {
    tasks = tasks.filter((task) => task.type === type);
  }

  return tasks;
}

async function removeTask(
  taskIdentification: { id?: string; destinationBusinessUnitId?: string; containerCode?: string },
  type: StockTransferTaskType,
  status?: StockTransferStatus
) {
  const { id = null, destinationBusinessUnitId = null, containerCode = null } = taskIdentification;

  let targetTask: Collection<StockTransferTaskInterface, string> | null = null;

  if (id) {
    targetTask = DB.stockTransferTasks.where('id').equals(id);
  } else if (destinationBusinessUnitId && containerCode) {
    targetTask = DB.stockTransferTasks.where({ destinationBusinessUnitId, containerCode });
  }

  if (targetTask) {
    if (status) {
      targetTask.and((task) => type === task.type && status === task.status).delete();
    } else {
      targetTask.and((task) => type === task.type).delete();
    }
  } else {
    throw new Error('Task not found error');
  }
}

async function clearTaskList(type?: string) {
  let tasks = DB.stockTransferTasks.where({ status: StockTransferStatus.SENT });
  if (type) {
    tasks = tasks.filter((task) => task.type === type);
  }
  await tasks.delete();
}

export { clearTaskList, createOrUpdateTask, createSealedContainer, getTasks, removeTask };
