import { MuiThemeProvider } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import App from './App';
import { lightTheme } from './theme';

const Root = ({ store, history }: { store: Store; history: History }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={lightTheme}>
          <App />
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default Root;
