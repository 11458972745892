import { ItemInventoryLocalTask, StockTakeStatus } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { DB } from '@meeva/service-client-core/modules/app/client/dataProvider/storageClient';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState } from 'react';

import { REASON_MISSING_ITEM_ANALYSIS } from '../../redux/modules/stockTake/types';

type InventoryLocation = 'primary' | 'secondary' | 'inventory';

type UseInventoryTaskResult = () => [ItemInventoryLocalTask[], ItemInventoryLocalTaskDisplayInterface[]];

interface ItemInventoryLocalTaskDisplayInterface {
  itemId: string;
  itemName: string;
  stockTakeCounting: { [key in InventoryLocation]: number };
}

const useInventoryTaskResult: UseInventoryTaskResult = () => {
  const [inventoryTaskIds, setInventoryTaskIds] = useState<string[]>([]);
  const [itemIds, setItemIds] = useState<string[]>([]);
  const [taskListToSend, setTaskListToSend] = useState<ItemInventoryLocalTask[]>([]);
  const [taskListToDisplay, setTaskListToDisplay] = useState<ItemInventoryLocalTaskDisplayInterface[]>([]);

  const inventoryTasks = useLiveQuery(
    () => DB.inventoryTasks.where('reason').equals(REASON_MISSING_ITEM_ANALYSIS).toArray(),
    []
  );

  const inventoryTasksByShelves = useLiveQuery(
    () => DB.inventoryTasksByShelves.where('taskIds').anyOf(inventoryTaskIds).distinct().toArray(),
    [inventoryTaskIds]
  );

  const stockTakeResults = useLiveQuery(
    () =>
      DB.stockTakeResults
        .where('itemId')
        .anyOf(itemIds)
        .and((task) => REASON_MISSING_ITEM_ANALYSIS === task.reason)
        .and(({ status }) => StockTakeStatus.DRAFT === status)
        .distinct()
        .toArray(),
    [itemIds]
  );

  useEffect(() => {
    setInventoryTaskIds(inventoryTasks?.map(({ id }) => id) || []);
    setItemIds(inventoryTasks?.map(({ itemId }) => itemId) || []);
  }, [inventoryTasks]);

  useEffect(() => {
    const _taskListToSend: ItemInventoryLocalTask[] = [];
    const _taskListToDisplay: ItemInventoryLocalTaskDisplayInterface[] = [];

    if (inventoryTasksByShelves?.length) {
      for (const shelf of inventoryTasksByShelves) {
        for (const task of shelf.tasks) {
          if (REASON_MISSING_ITEM_ANALYSIS === task.reason) {
            _taskListToSend.push(task as ItemInventoryLocalTask);

            const stockTakeCounting = { primary: 0, secondary: 0, inventory: 0 };

            stockTakeResults
              ?.filter(({ itemId }) => task.itemId === itemId)
              .forEach(({ location, amount }) => {
                if (location in stockTakeCounting) {
                  stockTakeCounting[location as InventoryLocation] = amount;
                }
              });

            _taskListToDisplay.push({
              itemId: task.itemId,
              itemName: task.item.description,
              stockTakeCounting,
            });
          }
        }
      }
    }

    setTaskListToSend(_taskListToSend);
    setTaskListToDisplay(_taskListToDisplay);
  }, [inventoryTasksByShelves, stockTakeResults]);

  return [taskListToSend, taskListToDisplay];
};

export default useInventoryTaskResult;
