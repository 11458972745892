import {
  Box,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SendIcon from '@material-ui/icons/Send';
import { Timeline, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import { ItemInventoryLocalTask } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import ConfirmDialog from '@meeva/service-client-core/modules/common/components/ConfirmDialog';
import { push } from 'connected-react-router';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { markTasksAsReady } from '../../redux/modules/stockTake/operations';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  actionTitle: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  timelineResult: {
    fontWeight: 'bold',
  },
  timelineDotSuccess: {
    backgroundColor: theme.palette.success.light,
  },
  timelineDotWarning: {
    backgroundColor: theme.palette.warning.light,
  },
  loadingModal: {
    display: 'flex',
  },
  loadingSpinner: {
    margin: 'auto',
  },
}));

const StyledTimelineItem = withStyles({
  root: {
    '&:before': {
      display: 'none',
    },
  },
})(TimelineItem);

interface ZoneCountListProps {
  tasks: ItemInventoryLocalTask[];
}
const Summary = ({ tasks }: ZoneCountListProps) => {
  const classes = useStyles();
  const completedShelfTasks = tasks.filter((task) => task.countings.length > 0);
  const numTotalCountedArticles = completedShelfTasks.reduce(
    (acc, task) => acc + task.countings.reduce((c, [, counting]) => c + counting.amount, 0),
    0
  );

  const dispatch = useDispatch();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isBackgroundProcessActive, setBackgroundProcessActive] = React.useState(false);

  const returnToInventoryStart = useCallback(() => dispatch(push('/itemInventory/zoneCount')), [dispatch]);
  const sendStockTakes = useCallback(() => {
    setBackgroundProcessActive(true);

    // Aufgaben welche in allen Standorten gezählt wurden
    const completedShelfTasks = tasks.filter((task) => task.countings.length > 0);

    (async () => {
      await dispatch(markTasksAsReady(completedShelfTasks));
      setBackgroundProcessActive(false);
      dispatch(push('/itemInventory'));
    })();
  }, [dispatch, tasks]);

  return (
    <React.Fragment>
      <Container>
        <Box mt={1}>
          <Paper>
            <Typography variant="h5" component="h2" className={classes.title}>
              Zonen zählen
            </Typography>

            <Timeline align="left">
              <StyledTimelineItem>
                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDotSuccess}>
                    <CheckIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  Gezählt:
                  <div className={classes.timelineResult}>{numTotalCountedArticles} Artikel</div>
                </TimelineContent>
              </StyledTimelineItem>
            </Timeline>
          </Paper>
        </Box>

        <Box mt={1}>
          <Paper>
            <Typography className={classes.actionTitle}>Was möchtest du als nächstes tun?</Typography>
            <List component="nav">
              <ListItem button color="primary">
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText primary="Zählung fortsetzen" onClick={returnToInventoryStart} />
              </ListItem>
              <ListItem button color="primary" onClick={() => setConfirmDialogOpen(true)}>
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary="Absenden" />
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Modal
          disableBackdropClick
          disableEnforceFocus
          disableAutoFocus
          className={classes.loadingModal}
          open={isBackgroundProcessActive}
          onClose={() => setBackgroundProcessActive(false)}
        >
          <CircularProgress className={classes.loadingSpinner} />
        </Modal>
      </Container>

      <ConfirmDialog
        open={confirmDialogOpen}
        title="Achtung"
        contentText="Bestätigung am Computer erforderlich"
        confirmText="Okay"
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={sendStockTakes}
      />
    </React.Fragment>
  );
};

export default Summary;
