import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  DistributionPackagingSlipDistributionTaskInterface,
  DistributionPackagingSlipInterface,
} from '@meeva/service-client-core/interfaces/DistributionPackagingSlipInterface';
import {
  setNavigationUpwardTarget,
  setSnack,
  setTitle,
  showGlobalProgressModal,
} from '@meeva/service-client-core/redux/interface/actions';
import { playErrorSound, playSuccessSound } from '@meeva/service-client-core/utils/audioHelper';
import { findItemByCode } from '@meeva/service-client-core/utils/items';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';

import ScanInput from '../../common/ScanInput';
import * as PackagingSlipHelper from '../utils/packagingSlipHelper';

export interface CustomRouterProps {
  packagingSlipIndicator: string | undefined;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box style={{ marginTop: 10 }}>{children}</Box>}
  </div>
);

const GroupedTaskTable = ({
  tasks,
  type,
}: {
  tasks: DistributionPackagingSlipDistributionTaskInterface[];
  type: string;
}) => {
  const [scanValue, setScanValue] = useState<string>('');

  const dispatch = useDispatch();
  const history = useHistory();

  const handleScan = async () => {
    let selectedItemNumber = scanValue;

    dispatch(showGlobalProgressModal(true));
    const item = await findItemByCode(scanValue);
    dispatch(showGlobalProgressModal(false));

    if (item) {
      selectedItemNumber = item.itemNo;
    }

    const scannedTask = tasks.filter((task) => selectedItemNumber === String(task.taskObject.number))[0] || null;

    if (!scannedTask) {
      playErrorSound('item');
      return dispatch(
        setSnack({
          severity: 'warning',
          autoHideDuration: 3000,
          text: `Es wurde keine Artikel mit der Nummer "${scanValue}" im Lieferschein gefunden`,
        })
      );
    }

    playSuccessSound('item');
    history.push(`/packagingSlip/store-distribution/${scannedTask.packagingSlipId}/${scannedTask.id}`);
  };

  tasks.sort((entryA, entryB) => {
    if (entryA.taskObject.number && entryB.taskObject.number) {
      return entryA.taskObject.number - entryB.taskObject.number;
    }

    return entryA.taskObject.name.localeCompare(entryB.taskObject.name);
  });

  return (
    <Fragment>
      {'Item' === type && (
        <ScanInput
          onChange={(value) => setScanValue(value as string)}
          value={scanValue}
          scanEvent={handleScan}
          inputType="text"
        />
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th">Nr.</TableCell>
              <TableCell component="th">Name</TableCell>
              <TableCell component="th" align="right">
                Bearbeiten
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!tasks.length ? (
              <TableRow>
                <TableCell align={'center'} colSpan={3}>
                  Keine Informationen vorhanden
                </TableCell>
              </TableRow>
            ) : (
              tasks.map(({ id, taskObject, packagingSlipId }, i) => (
                <TableRow
                  key={i}
                  onClick={() => history.push(`/packagingSlip/store-distribution/${packagingSlipId}/${id}`)}
                >
                  <TableCell>{taskObject.number}</TableCell>
                  <TableCell>{taskObject.name}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => history.push(`/packagingSlip/store-distribution/${packagingSlipId}/${id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const PackagingSlipDetailView = ({ match }: RouteComponentProps<CustomRouterProps>) => {
  const [packagingSlip, setPackagingSlip] = useState<DistributionPackagingSlipInterface>();
  const [itemTasks, setItemTasks] = useState<DistributionPackagingSlipDistributionTaskInterface[]>([]);
  const [unitTasks, setUnitTasks] = useState<DistributionPackagingSlipDistributionTaskInterface[]>([]);
  const [isTaskListLoading, setIsTaskListLoading] = useState<boolean>(false);
  const [taskType, setTaskType] = useState<'BusinessUnit' | 'Item'>('BusinessUnit');

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { packagingSlipIndicator } = match.params;

    dispatch(setTitle(`Lieferung`));
    dispatch(setNavigationUpwardTarget(`/packagingSlip`));

    if (!packagingSlipIndicator) {
      return history.push('/packagingSlip');
    }

    (async () => {
      const tmpPackagingSlip = await PackagingSlipHelper.getEntryById(packagingSlipIndicator);

      if (!tmpPackagingSlip) {
        dispatch(
          setSnack({
            severity: 'warning',
            autoHideDuration: 3000,
            text: 'Es wurde keine Lieferung gefunden',
          })
        );

        return history.push('/packagingSlip');
      }

      setPackagingSlip(tmpPackagingSlip);

      await fetchTasks(tmpPackagingSlip);
    })();
  }, [match]);

  const fetchTasks = useCallback(
    async (newPackagingSlip: DistributionPackagingSlipInterface) => {
      setIsTaskListLoading(true);

      try {
        const _itemTasks = await Promise.all(
          newPackagingSlip.itemDistributions.map(async (itemDistribution) => {
            let _task = await PackagingSlipHelper.getTask(newPackagingSlip.id, itemDistribution.itemId, 'Item');

            if (!_task) {
              _task = await PackagingSlipHelper.createTask(
                newPackagingSlip.id,
                itemDistribution.id, //lineItemId
                'Item'
              );
            }

            return _task;
          })
        );

        const usedBusinessUnitIds: string[] = [];
        const _unitTasks = [];
        for (const itemDistribution of newPackagingSlip.itemDistributions) {
          for (const businessUnit of itemDistribution.distributedBusinessUnits) {
            if (usedBusinessUnitIds.includes(businessUnit.id)) {
              continue;
            }

            let _task = await PackagingSlipHelper.getTask(newPackagingSlip.id, businessUnit.id, 'BusinessUnit');

            if (!_task) {
              _task = await PackagingSlipHelper.createTask(newPackagingSlip.id, businessUnit.id, 'BusinessUnit');
            }

            usedBusinessUnitIds.push(businessUnit.id);
            _unitTasks.push(_task);
          }
        }

        _itemTasks.length && setItemTasks(_itemTasks as DistributionPackagingSlipDistributionTaskInterface[]);
        _unitTasks.length && setUnitTasks(_unitTasks as DistributionPackagingSlipDistributionTaskInterface[]);
      } catch (error) {
        console.error(error);
      }

      setIsTaskListLoading(false);
    },
    [packagingSlip]
  );

  return (
    <Container>
      <Box mt={1}>
        <Card>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="textSecondary">
                {packagingSlip?.createdAt
                  ? `Lieferung ${packagingSlip?.serialFormId} vom ${new Intl.DateTimeFormat('de-DE').format(
                      new Date(packagingSlip?.createdAt)
                    )}`
                  : `Lieferung-ID ${packagingSlip?.id}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CardContent>
                <dl>
                  <dt>Lieferscheinnummer</dt>
                  <dd>{packagingSlip?.serialFormId}</dd>
                </dl>
                <dl>
                  <dt>Bestellnummer</dt>
                  <dd>{packagingSlip?.relatedOrder?.orderReferenceId}</dd>
                </dl>
                <dl>
                  <dt>Erstellt am</dt>
                  <dd>
                    {packagingSlip?.createdAt &&
                      new Intl.DateTimeFormat('de-DE').format(new Date(packagingSlip.createdAt))}
                  </dd>
                </dl>
                <dl>
                  <dt>Lieferant</dt>
                  <dd>{packagingSlip?.supplier}</dd>
                </dl>
              </CardContent>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Box>
      <Box mt={1}>
        <Card>
          <Tabs variant="fullWidth" value={taskType} onChange={(_, newValue) => setTaskType(newValue)}>
            <Tab label="Filiale" value="BusinessUnit" disabled={isTaskListLoading} />
            <Tab label="Artikel" value="Item" disabled={isTaskListLoading} />
          </Tabs>
          <CardContent>
            {isTaskListLoading && !packagingSlip ? (
              <div>Filialen und Artikel werden geladen...</div>
            ) : (
              <Fragment>
                <TabPanel value={taskType} index="BusinessUnit">
                  <GroupedTaskTable tasks={unitTasks} type={taskType} />
                </TabPanel>
                <TabPanel value={taskType} index="Item">
                  <GroupedTaskTable tasks={itemTasks} type={taskType} />
                </TabPanel>
              </Fragment>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default PackagingSlipDetailView;
