import {
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Cancel, Clear, Menu, Send } from '@material-ui/icons';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import {
  ItemCollectorTaskStatus,
  ItemCollectorTaskType,
} from '@meeva/service-client-core/interfaces/ItemCollectorTaskInterface';
import { StockTakeStatus } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { ItemInterface } from '@meeva/service-client-core/interfaces/ItemListInterface';
import { DB } from '@meeva/service-client-core/modules/app/client/dataProvider/storageClient';
import useDefaultStyles from '@meeva/service-client-core/modules/common/hooks/useDefaultStyles';
import { AppMode, resetAppMode, setAppMode } from '@meeva/service-client-core/redux/appBehavior/appModeSlice';
import { setSnack, setTitle, showGlobalProgressModal } from '@meeva/service-client-core/redux/interface/actions';
import { triggerBackgroundSync } from '@meeva/service-client-core/redux/interface/operations';
import * as ItemCollectorTask from '@meeva/service-client-core/utils/itemCollectorTaskHelper';
import InventoryTasksLoader from '@meeva/service-client-core/webworker/serviceWorker/InventoryTasksLoader';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router';

import ScanInput from '../common/ScanInput';
import useInventoryTaskResult from './useInventoryTaskResult';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '100px', // SpeedDial should not overlap with table
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  tableCellQuantity: {
    whiteSpace: 'nowrap',
  },
}));

const MissingItemAnalysisView = () => {
  const [speedDialOpen, setSpeedDialOpen] = useState<boolean>(false);
  const [taskListToSend, taskListToDisplay] = useInventoryTaskResult();
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  //TODO: Implement RootState (@meeva/service-client-core/redux/store) with store.getState types
  const appModeState = useSelector((state: any) => state.app.mode);

  const itemCollectorTask = useLiveQuery(
    () =>
      DB.itemCollectorTasks
        .where('type')
        .equals(ItemCollectorTaskType.MISSING_ITEM_ANALYSIS_COLLECTION)
        .and((task) => ItemCollectorTaskStatus.FORCE_PUSH !== task?.status)
        .first(),
    []
  );

  useEffect(() => {
    dispatch(setTitle('Fehlartikelanalyse'));
  }, []);

  const handleScan = async (itemCode: string) => {
    const item = await DB.items.where('itemNo').equals(itemCode).or('scanCodes').equals(itemCode).first();

    if (!item) {
      return dispatch(
        setSnack({
          text: `Der Artikel wurde nicht gefunden`,
          severity: 'warning',
          autoHideDuration: 3000,
        })
      );
    }

    dispatch(setAppMode(AppMode.MISSING_ITEM_ANALYSIS_MODE));

    await addItemToMissingItemAnalysisTask(item);

    history.push(`/items/details/byCode/${item.itemNo}`);
  };

  const handleAnalysisFinalization = async () => {
    dispatch(showGlobalProgressModal(true));
    try {
      await DB.transaction('rw', DB.stockTakeResults, DB.inventoryTasks, () => {
        for (const _task of taskListToSend) {
          DB.inventoryTasks.delete(_task.id);

          DB.stockTakeResults
            .where('itemId')
            .equals(_task.itemId)
            .and(({ reason }) => reason === _task.reason)
            .modify({ status: StockTakeStatus.READY });
        }
      });

      if (itemCollectorTask) {
        ItemCollectorTask.updateTask(itemCollectorTask.id, {
          ...itemCollectorTask,
          status: ItemCollectorTaskStatus.FORCE_PUSH,
        });
        dispatch(triggerBackgroundSync('itemCollectorTasks', true));
      }

      await InventoryTasksLoader.updateShelves(taskListToSend);
      dispatch(triggerBackgroundSync('stockTakeResults', true));

      dispatch(
        setSnack({
          text: 'Die Fehlartikelanalyse wird nun im Hintergrund übertragen',
          autoHideDuration: 5000,
          severity: 'success',
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showGlobalProgressModal(false));
    }

    dispatch(resetAppMode());
  };

  const abortCurrentAnalysis = () => {
    dispatch(
      setSnack({
        text: 'Die Fehlartikelanalyse wurde abgebrochen. Erstellte Zählungen bleiben erhalten',
        autoHideDuration: 5000,
        severity: 'success',
      })
    );

    dispatch(resetAppMode());
  };

  const addItemToMissingItemAnalysisTask = async (itemToAdd: ItemInterface) => {
    try {
      dispatch(showGlobalProgressModal(true));

      // There should be only one task for this specific list
      const task = (await ItemCollectorTask.getTasksByType(ItemCollectorTaskType.MISSING_ITEM_ANALYSIS_COLLECTION)).at(
        0
      );

      if (!task) {
        ItemCollectorTask.createTask({
          id: '', // will be set later
          description: 'Fehlartikelanalyse',
          timestamp: new Date(),
          type: ItemCollectorTaskType.MISSING_ITEM_ANALYSIS_COLLECTION,
          items: [
            {
              ...itemToAdd,
              timestamp: new Date(),
              quantity: 0, // every item in this tasks should be missing
            },
          ],
        });
      } else {
        const hasItemAlreadyBeenScanned = Boolean(task.items.find((_item) => _item.id === itemToAdd.id));

        if (hasItemAlreadyBeenScanned) {
          dispatch(
            setSnack({
              text: `Der Artikel wurde bereits aufgenommen`,
              severity: 'warning',
              autoHideDuration: 3000,
            })
          );

          return;
        }

        ItemCollectorTask.updateTask(task.id, {
          ...task,
          items: [
            ...task.items,
            {
              ...itemToAdd,
              timestamp: new Date(),
              quantity: 0, // every item in this tasks should be missing
            },
          ],
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(showGlobalProgressModal(false));
    }
  };

  return (
    <>
      <Container className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper className={defaultClasses.paper}>
              <ScanInput scanEvent={handleScan} cypressTag="missing-item-analysis-scan-input" />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {itemCollectorTask?.items?.length ? (
                  <TableContainer>
                    <Table data-cy="missing-item-analysis-item-table">
                      <TableHead>
                        <TableRow>
                          <TableCell component="th">Artikel</TableCell>
                          <TableCell component="th" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itemCollectorTask.items.map(({ description, itemNo }, index) => (
                          <TableRow key={index}>
                            <TableCell>{itemNo}</TableCell>
                            <TableCell>{description}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
                {itemCollectorTask?.items?.length ? (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAnalysisFinalization}
                      disabled={AppMode.MISSING_ITEM_ANALYSIS_MODE !== appModeState}
                      fullWidth
                      data-cy="missing-item-analysis-submit-button"
                    >
                      Fehlartikelanalyse abschliessen
                    </Button>
                  </Grid>
                ) : (
                  <Typography color="textPrimary" align="center" gutterBottom data-cy="missing-item-analysis-hint-text">
                    Scanne einen Artikel um die Fehlartikelanalyse zu beginnen
                    <br />
                    <br />
                    <b>Achtung:</b> Bereits erfasste Artikel innerhalb der Analyse können nicht entfernt werden!
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <SpeedDial
        ariaLabel="Menu"
        className={classes.fab}
        icon={<SpeedDialIcon icon={<Menu />} openIcon={<Clear />} />}
        onOpen={(_, reason) => 'mouseEnter' !== reason && setSpeedDialOpen(true)}
        onClose={(_, reason) => 'mouseLeave' !== reason && setSpeedDialOpen(false)}
        open={speedDialOpen}
      >
        <SpeedDialAction
          color="secondary"
          icon={<Cancel />}
          tooltipTitle="Abbrechen"
          onClick={() => {
            setSpeedDialOpen(false);
            abortCurrentAnalysis();
          }}
          FabProps={{ disabled: AppMode.MISSING_ITEM_ANALYSIS_MODE !== appModeState }}
          tooltipOpen
        />
        <SpeedDialAction
          color="secondary"
          icon={<Send />}
          tooltipTitle="Übertragen"
          onClick={async () => {
            setSpeedDialOpen(false);
            await handleAnalysisFinalization();
          }}
          FabProps={{ disabled: !taskListToDisplay?.length }}
          tooltipOpen
        />
      </SpeedDial>
    </>
  );
};

const MissingItemAnalysisIndex = () => (
  <Switch>
    <Route component={MissingItemAnalysisView} />
  </Switch>
);

export default MissingItemAnalysisIndex;
