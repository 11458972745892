import {
  DistributionPackagingSlipDestinationBusinessUnitInterface,
  DistributionPackagingSlipDistributionTaskObjectInterface,
  DistributionPackagingSlipItemDistributionStatus,
} from '@meeva/service-client-core/interfaces/DistributionPackagingSlipInterface';
import { DB } from '@meeva/service-client-core/modules/app/client/dataProvider/storageClient';
import { v4 as uuidv4 } from 'uuid';

const getEntries = async () => (await DB.distributionPackagingSlips.toArray()) ?? [];

const getEntryById = async (id: string) => DB.distributionPackagingSlips.get({ id });

const getTaskById = async (id: string) => DB.distributionPackagingSlipDistributionTasks.get({ id });

const getTask = async (packagingSlipId: string, id: string, type: 'BusinessUnit' | 'Item') =>
  DB.distributionPackagingSlipDistributionTasks
    .where('packagingSlipId')
    .equals(packagingSlipId)
    .and((task) => type === task.taskType && id === task.taskObject.id)
    .first();

const updateTaskDistributedQuantity = async (
  packagingSlipId: string,
  taskObjectId: string,
  distributionId: string,
  containerCode: string,
  quantity: number,
  type?: string
): Promise<boolean> => {
  const success = await DB.distributionPackagingSlipDistributionTasks
    .where('packagingSlipId')
    .equals(packagingSlipId)
    .and((task) => taskObjectId === task.taskObject.id)
    .modify((task) => {
      // Change status to ready on first time update
      if (DistributionPackagingSlipItemDistributionStatus.DRAFT === task.status) {
        task.status = DistributionPackagingSlipItemDistributionStatus.READY;
      }

      task.distribution.forEach((value) => {
        if (distributionId === value.id) {
          if (containerCode && !value.containers?.length) {
            value.containers = [{ code: containerCode, unitCount: 0 }];
          }

          if (type && 'override' === type) {
            value.actualDistributedUnitCount = quantity;

            containerCode &&
              value.containers!.forEach((container) => {
                if (containerCode === container.code) {
                  container.unitCount = quantity;
                }
              });
          } else {
            value.actualDistributedUnitCount += quantity;

            containerCode &&
              value.containers!.forEach((container) => {
                if (containerCode === container.code) {
                  container.unitCount += quantity;
                }
              });
          }
        }
      });

      task.updatedAt = new Date();
    });

  return Boolean(success);
};

const createTask = async (packagingSlipId: string, taskObjectId: string, type: 'BusinessUnit' | 'Item') => {
  const entry = await getEntryById(packagingSlipId);

  if (!entry) {
    return;
  }

  let taskDistribution: any[] = [];
  let taskObject: DistributionPackagingSlipDistributionTaskObjectInterface | null = null;

  if ('Item' === type) {
    const itemEntry =
      entry.itemDistributions.filter((distributionItem) => taskObjectId === distributionItem.id)[0] || null;

    if (!itemEntry) {
      return;
    }

    taskObject = {
      id: itemEntry.itemId,
      name: itemEntry.itemName,
      number: Number(itemEntry.itemNumber),
      lineItemId: taskObjectId,
    };

    taskDistribution = itemEntry.distributedBusinessUnits.map((value) => ({
      id: value.id,
      name: value.name,
      distributionInformationType: 'BusinessUnit',
      ...(value.number ? { number: Number(value.number) } : {}),
      distributedUnitCount: value.distributedUnitCount,
      actualDistributedUnitCount: 0,
    }));
  }

  if ('BusinessUnit' === type) {
    const assignedItems: Array<{
      itemId: string;
      lineItemId: string;
      itemName: string;
      itemNumber?: string;
    }> = [];
    let unitEntry: DistributionPackagingSlipDestinationBusinessUnitInterface | null = null;

    for (const distributionItem of entry.itemDistributions) {
      for (const businessUnit of distributionItem.distributedBusinessUnits) {
        if (taskObjectId === businessUnit.id) {
          if (!unitEntry) {
            unitEntry = businessUnit;
          }

          let alreadyAssigned = false;

          for (const itemToBeAssigned of assignedItems) {
            if (itemToBeAssigned.itemId === distributionItem.itemId) {
              alreadyAssigned = true;
              break;
            }
          }

          if (!alreadyAssigned) {
            assignedItems.push({
              itemId: distributionItem.itemId,
              lineItemId: distributionItem.id,
              itemName: distributionItem.itemName,
              ...(distributionItem.itemNumber ? { itemNumber: distributionItem.itemNumber } : {}),
            });
          }
        }
      }
    }

    if (!unitEntry) {
      return;
    }

    taskObject = {
      id: unitEntry.id,
      name: unitEntry.name,
      ...(unitEntry.number ? { number: unitEntry.number } : {}),
    };

    taskDistribution = assignedItems.map((value) => ({
      id: value.itemId,
      name: value.itemName,
      lineItemId: value.lineItemId,
      distributionInformationType: 'Item',
      ...(value.itemNumber ? { number: value.itemNumber } : {}),
      distributedUnitCount: 0,
      actualDistributedUnitCount: 0,
    }));
  }

  if (!taskObject) {
    return;
  }

  const newTaskId = await DB.distributionPackagingSlipDistributionTasks.add({
    id: uuidv4(),
    packagingSlipId,
    createdAt: new Date(),
    updatedAt: new Date(),
    status: DistributionPackagingSlipItemDistributionStatus.DRAFT,
    taskType: type,
    taskObject: taskObject!,
    distribution: taskDistribution,
  });

  return DB.distributionPackagingSlipDistributionTasks.get({ id: newTaskId });
};

const createOrUpdateCountTask = async (
  packagingSlipId: string,
  itemId: string,
  containerCode: string,
  destinationBusinessUnitId: string,
  quantity: number
) => {
  const countTaskSuccess = await DB.distributionPackagingSlipQueueTasks
    .where('packagingSlipId')
    .equals(packagingSlipId)
    .and(
      (filter) =>
        filter.itemId === itemId &&
        filter.containerCode === containerCode &&
        filter.destinationBusinessUnitId === destinationBusinessUnitId
    )
    .modify((task) => (task.packUnitCount += quantity));

  if (!countTaskSuccess) {
    await DB.distributionPackagingSlipQueueTasks.add({
      id: uuidv4(),
      packagingSlipId,
      containerCode,
      destinationBusinessUnitId,
      itemId,
      packUnitCount: quantity,
    });
  }
};

export {
  createOrUpdateCountTask,
  createTask,
  getEntries,
  getEntryById,
  getTask,
  getTaskById,
  updateTaskDistributedQuantity,
};
