import React from 'react';
import { Route, Switch } from 'react-router';

import PackagingSlipDetailView from './components/PackagingSlipDetailView';
import PackagingSlipListView from './components/PackagingSlipListView';
import PackagingSlipStoreDistributionTaskView from './components/PackagingSlipStoreDistributionTaskView';

const PackagingSlipRouter = () => (
  <Switch>
    <Route path="/packagingSlip/:packagingSlipIndicator" exact component={PackagingSlipDetailView} />
    <Route
      path="/packagingSlip/store-distribution/:packagingSlipIndicator/:taskId"
      exact
      component={PackagingSlipStoreDistributionTaskView}
    />
    <Route component={PackagingSlipListView} />
  </Switch>
);

export default PackagingSlipRouter;
