import { Box, makeStyles } from '@material-ui/core';
import { ItemInventoryLocalTask, StockTakeStatus } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { setSnack } from '@meeva/service-client-core/redux/interface/actions';
import { mongoObjectId } from '@meeva/service-client-core/utils/db';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addStockTake, addTasksIfNotExists } from '../../redux/modules/stockTake/operations';
import { REASON_FREE_INVENTORY_ZONE } from '../../redux/modules/stockTake/types';
import useZoneBarcodeScan from '../common/hooks/useZoneBarcodeScan';
import ItemInventoryTaskForm from '../common/ItemInventoryTaskForm';
import ScanInput from '../common/ScanInput';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  divider: {
    backgroundColor: '#ececec',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: 'auto',
  },
  accordionDetails: {
    padding: 0,
    flexDirection: 'column',
  },
  taskList: {
    width: '100%',
  },
  loadingModal: {
    display: 'flex',
  },
  loadingSpinner: {
    margin: 'auto',
  },
}));

interface TaskInputProps {
  taskFormItem: ItemInventoryLocalTask | undefined;
  setTaskFormItem: (task: ItemInventoryLocalTask | undefined) => void;
}

export const TaskInput = ({ taskFormItem, setTaskFormItem }: TaskInputProps) => {
  const [currentZone, scanZoneBarcode] = useZoneBarcodeScan();
  const inventoryLocation = [currentZone!, currentZone!] as [string, string];
  const dispatch = useDispatch();
  const classes = useStyles();
  const onScan = useCallback(async (code: string) => {
    const zone = scanZoneBarcode(code);
    if (zone) {
      const task = {
        id: mongoObjectId(),
        itemId: '_zone_' + zone,
        item: {
          id: '_zone_' + zone,
          itemNo: '******',
          description: 'Zone ' + zone,
          targetStockQty: null,
          minTargetStockQty: 0,
          seriesItemId: null,
          stockQty: 0,
          saleUnitPerPackUnitCount: 0,
          updatedAt: '',
          posIdentities: [
            {
              id: '_zone_' + zone,
              posItemId: code,
              posItemIdQualifier: 'gtin', // Als gtin ausgeben, damit nicht die Artikel-Nummer **** als Nummer angezeigt wird
            },
          ],
          scanCodes: [code],
          shelfPlacements: [],
          suppliers: [],
          routeArticle: false,
          productGroup: 0,
          shelves: [],
        },
        priority: 0,
        reason: REASON_FREE_INVENTORY_ZONE,
        shelfNumber: -1,
        countings: [],
      };
      await dispatch(addTasksIfNotExists(task));

      setTaskFormItem(task);
      return;
    }

    dispatch(
      setSnack({
        autoHideDuration: 5000,
        severity: 'warning',
        text: `Bitte eine Zone scannen.`,
      })
    );
  }, []);

  const closeTaskForm = useCallback(() => setTaskFormItem(undefined), [setTaskFormItem]);
  const lowerLimit = 0;
  const upperLimit = 9999;

  const updateTaskCounting = useCallback(
    async (qty?: number) => {
      dispatch(
        addStockTake('replace', {
          amount: qty || 0,
          itemId: taskFormItem!.itemId,
          timestamp: Date.now(),
          location: currentZone!,
          reason: REASON_FREE_INVENTORY_ZONE,
          status: StockTakeStatus.DRAFT,
        })
      );

      closeTaskForm();
    },
    [dispatch, currentZone, taskFormItem, closeTaskForm]
  );

  return (
    <>
      {taskFormItem !== undefined && (
        <ItemInventoryTaskForm
          task={taskFormItem}
          mode={'replace'}
          inventoryLocation={inventoryLocation}
          onClose={closeTaskForm}
          onSave={updateTaskCounting}
          lowerStockLimit={lowerLimit}
          upperStockLimit={upperLimit}
        />
      )}
      {/*{currentZone && (*/}
      {/*  <Typography variant="h6" component="h6" className={classes.subtitle}>*/}
      {/*    Zone: {currentZone}*/}
      {/*  </Typography>*/}
      {/*)}*/}
      <Box className={classes.progress}>
        <ScanInput scanEvent={onScan} inputType="text" />
      </Box>
      <Box className={classes.progress}></Box>
    </>
  );
};
