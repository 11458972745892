enum DistributionPackagingSlipItemDistributionStatus {
  DRAFT,
  READY,
}

interface DistributionPackagingSlipInterface {
  id: string;
  createdAt: Date;
  serialFormId?: string;
  currentState: string;
  supplier?: string | null;
  relatedOrder?: DistributionPackagingSlipRelatedOrderInterface | null;
  itemDistributions: DistributionPackagingSlipItemDistributionInterface[];
}

interface DistributionPackagingSlipDistributionTaskInterface {
  id: string;
  packagingSlipId: string;
  createdAt: Date;
  updatedAt: Date | null;
  status: DistributionPackagingSlipItemDistributionStatus;
  taskType: 'BusinessUnit' | 'Item';
  taskObject: DistributionPackagingSlipDistributionTaskObjectInterface;
  distribution: DistributionPackagingSlipDistributionTaskDistributionInterface[];
}

interface DistributionPackagingSlipQueueTaskInterface {
  id: string;
  packagingSlipId: string;
  itemId: string;
  destinationBusinessUnitId: string;
  packUnitCount: number;
  containerCode?: string;
}

interface DistributionPackagingSlipRelatedOrderInterface {
  id: string;
  orderReferenceId: number;
  completeDateTimestamp?: Date;
  orderNumber?: number | null;
}

interface DistributionPackagingSlipItemDistributionInterface {
  id: string;
  itemId: string;
  itemName: string;
  itemNumber: string;
  itemUnitCount: number;
  distributedBusinessUnits: DistributionPackagingSlipDestinationBusinessUnitInterface[];
}

interface DistributionPackagingSlipDestinationBusinessUnitInterface {
  id: string;
  name: string;
  number?: number;
  distributedUnitCount: number;
}

interface DistributionPackagingSlipDistributionTaskObjectInterface {
  id: string;
  name: string;
  number?: number;
  lineItemId?: string;
}

interface DistributionPackagingSlipDistributionTaskDistributionInterface {
  id: string;
  name: string;
  number?: number;
  lineItemId?: string;
  distributedUnitCount: number;
  actualDistributedUnitCount: number;
  containers?: Array<{ code: string; unitCount: number }>;
}

export { DistributionPackagingSlipItemDistributionStatus };
export type {
  DistributionPackagingSlipDestinationBusinessUnitInterface,
  DistributionPackagingSlipDistributionTaskDistributionInterface,
  DistributionPackagingSlipDistributionTaskInterface,
  DistributionPackagingSlipDistributionTaskObjectInterface,
  DistributionPackagingSlipInterface,
  DistributionPackagingSlipItemDistributionInterface,
  DistributionPackagingSlipQueueTaskInterface,
};
