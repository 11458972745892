import { ItemInventoryLocalTask, ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import {
  setNavigationUpwardTarget,
  setTitle,
  showGlobalProgressModal,
} from '@meeva/service-client-core/redux/interface/actions';
import { getLocations } from '@meeva/service-client-core/utils/inventoryTaskHelper';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import ItemInventoryTaskList from './ItemInventoryTaskList';

interface ShelfTasksRouteProps {
  type: string;
  inventoryLocationName?: string;
}

interface ShelfTasksProps {
  tasks: Array<[number, ShelfListing]>;
  bulkSetZeroAllowed?: boolean;
}

interface ShelfTasksConnectedProps extends ShelfTasksProps, RouteComponentProps<ShelfTasksRouteProps> {
  setTitle: typeof setTitle;
}

const FreeShelfTasks = ({ setTitle, ...props }: ShelfTasksConnectedProps) => {
  const [isLoading, setLoading] = React.useState(true);
  const [tasks, setTasks] = React.useState([] as ItemInventoryLocalTask[]);
  const [bulkSetZeroAllowed, setBulkSetZeroAllowed] = React.useState(false);
  const [inventoryLocation, setInventoryLocation] = React.useState<[string, string] | undefined>(undefined);
  const [locations, setLocations] = React.useState(new Map<string, string>([]));

  const { history, tasks: tasksByShelf } = props;
  const { inventoryLocationName } = props.match.params;
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle('Freies Scannen');
    dispatch(setNavigationUpwardTarget(`/itemInventory`));
  }, [setTitle]);

  useEffect(() => {
    const allTasks = [] as ItemInventoryLocalTask[];
    tasksByShelf.forEach(([, shelf]) => allTasks.push(...shelf.tasks));
    setTasks(allTasks);
  }, [tasksByShelf]);

  useEffect(() => {
    if (!tasksByShelf || !inventoryLocation) {
      setLoading(false);
      return;
    }

    const locationEntries = Array.from(locations.entries());
    const currentInventoryLocationIndex = locationEntries.findIndex((item) => item[0] === inventoryLocation[0]);

    // remove shelfTasks without countings in the previous stages
    const previousStages = locationEntries
      .slice(0, currentInventoryLocationIndex)
      .map(([locationName]) => locationName);

    setBulkSetZeroAllowed(previousStages.length > 0);

    setLoading(false);
  }, [tasksByShelf, inventoryLocation, locations]);

  useEffect(() => {
    setLocations(getLocations());
  }, []);

  useEffect(() => {
    const validLocationNames = Array.from(locations.entries()).map(([name]) => name);
    if (inventoryLocationName && validLocationNames.includes(inventoryLocationName)) {
      const locationIndex = validLocationNames.indexOf(inventoryLocationName);
      if (locationIndex === 0) {
        dispatch(setNavigationUpwardTarget(`/itemInventory`));
      } else {
        dispatch(
          setNavigationUpwardTarget(
            `/itemInventory/type/${props.match.params.type}/location/${validLocationNames[locationIndex - 1]}`
          )
        );
      }
      setInventoryLocation([inventoryLocationName, locations.get(inventoryLocationName)!]);
      return;
    }

    setInventoryLocation(Array.from(locations.entries())[0]);
  }, [locations, inventoryLocationName]);

  const onTaskListComplete = React.useCallback(() => {
    if (!inventoryLocation) {
      return;
    }

    const locationEntries = Array.from(locations.entries());
    const currentInventoryLocationIndex = locationEntries.findIndex((item) => item[0] === inventoryLocation[0]);
    if (currentInventoryLocationIndex < locationEntries.length - 1) {
      // move to next location
      history.push(
        `/itemInventory/type/${props.match.params.type}/location/${
          locationEntries[currentInventoryLocationIndex + 1][0]
        }`
      );
    } else {
      // show summary
      history.push(`/itemInventory/type/${props.match.params.type}/summary`);
    }
  }, [history, inventoryLocation, locations]);

  useEffect(() => {
    dispatch(showGlobalProgressModal(isLoading));
  }, [isLoading]);

  if (isLoading || !inventoryLocation) {
    return null;
  }

  return (
    <ItemInventoryTaskList
      tasks={tasks}
      bulkSetZeroAllowed={bulkSetZeroAllowed}
      inventoryLocation={inventoryLocation}
      onListComplete={onTaskListComplete}
    />
  );
};

export default connect(null, {
  setTitle,
})(withRouter(FreeShelfTasks)) as React.ComponentType<ShelfTasksProps>;
