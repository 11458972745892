import {
  Container,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { setSnack, setTitle } from '@meeva/service-client-core/redux/interface/actions';
import { playErrorSound, playSuccessSound } from '@meeva/service-client-core/utils/audioHelper';
import classNames from 'classnames';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { rowStyles } from '../../freeStockTransfer/components/StockTransferItemRow';
import * as RequestStockTransferHelper from '../utils/requestStockTransferHelper';
import { BusinessUnitStatusInterface } from '../utils/requestStockTransferHelper';

// TODO: Generic hook for all Paper-Components?
const useDefaultStyles = makeStyles({
  paper: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  paperSearch: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
});

const useRowStyles = makeStyles({
  ...rowStyles,
  paper: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  paperSearch: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
});

const RequestStockTransferList = () => {
  const classes: any = useRowStyles();

  const [scannedCode, setScannedCode] = useState<string>('');
  const [pagination, setPagination] = useState({ currentPage: 0, qtyRows: 10 });
  const dispatch = useDispatch();
  const history = useHistory();
  const scannerInputRef = useRef<HTMLInputElement>();
  const tableData: BusinessUnitStatusInterface[] =
    useLiveQuery(() => RequestStockTransferHelper.getBusinessUnitsWithEntries()) || [];

  useEffect(() => {
    dispatch(setTitle('Filialtausch Packen'));
  }, []);

  useEffect(() => {
    if (scannerInputRef.current) {
      scannerInputRef.current.focus();
    }
  }, [scannedCode]);

  const handleScan = () => {
    const selectedEntry = tableData.find((data) => +(data.number || 0) === +scannedCode.substring(1));

    if (!selectedEntry) {
      playErrorSound('others');
      return dispatch(
        setSnack({
          autoHideDuration: 5000,
          severity: 'warning',
          text: 'Das gesuchte Dokument konnte nicht gefunden werden',
        })
      );
    }

    playSuccessSound('others');
    history.push(`${history.location.pathname}/${selectedEntry.id}/businessUnit`);
  };

  return (
    <Container>
      <Paper className={classes.paper}>
        <TextField
          fullWidth
          label="Barcode scannen oder eingeben"
          variant="outlined"
          inputRef={scannerInputRef}
          value={scannedCode}
          onChange={(event) => setScannedCode(event.target.value)}
          onKeyDown={(event) => {
            if ('Enter' === event.key || '13' === event.code) {
              event.preventDefault();
              handleScan();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Suche starten" onClick={() => handleScan} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Filiale</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length ? (
                tableData
                  .slice(
                    pagination.currentPage * pagination.qtyRows,
                    pagination.currentPage * pagination.qtyRows + pagination.qtyRows
                  )
                  .map((data, i) => {
                    return (
                      <TableRow
                        key={i}
                        className={classNames(classes.itemRow, classes['statusCode' + data.status])}
                        onClick={() => history.push(`${history.location.pathname}/${data.id}/businessUnit`)}
                      >
                        <TableCell>{data.number ? `${data.number} - ${data.name}` : data.name}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => history.push(`${history.location.pathname}/${data.id}/businessUnit`)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Keine Filialtauschanforderungen geladen
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            labelRowsPerPage="Zeilen:"
            count={tableData.length}
            page={pagination.currentPage}
            rowsPerPage={pagination.qtyRows}
            onPageChange={(_, value) => setPagination((prevState) => ({ ...prevState, currentPage: value }))}
            onRowsPerPageChange={(event) =>
              setPagination((prevState) => ({
                ...prevState,
                qtyRows: Number(event.target.value) || 100,
              }))
            }
          />
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default RequestStockTransferList;

export { useDefaultStyles };
