import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  Modal,
  Paper,
  TablePagination,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ItemCollectionListInterface } from '@meeva/service-client-core/interfaces/ItemCollectorTaskInterface';
import {
  ItemInventoryLocalTask,
  ItemInventoryStockUpdateMode,
  StockTakeStatus,
} from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import AppConfig from '@meeva/service-client-core/modules/app/config';
import ItemImageDialog from '@meeva/service-client-core/modules/common/components/ItemImageDialog';
import useQrCodeScan from '@meeva/service-client-core/modules/common/hooks/useQrCodeScan';
import { AppMode } from '@meeva/service-client-core/redux/appBehavior/appModeSlice';
import { setSnack, showGlobalProgressModal } from '@meeva/service-client-core/redux/interface/actions';
import { removeItemToProcess } from '@meeva/service-client-core/redux/items/detail/actions';
import { getItemByProcess } from '@meeva/service-client-core/redux/items/detail/selectors';
import { usePermission } from '@meeva/service-client-core/redux/security/selectors';
import { playErrorSound, playSuccessSound } from '@meeva/service-client-core/utils/audioHelper';
import { mongoObjectId } from '@meeva/service-client-core/utils/db';
import { getListById as getItemCollectionListById } from '@meeva/service-client-core/utils/itemCollectionListHelper';
import { codeToSearchList, findItemByCode } from '@meeva/service-client-core/utils/items';
import React, { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';

import { addStockTake, addTasks, removeTasksIfEmpty } from '../../redux/modules/stockTake/operations';
import { REASON_FREE_INVENTORY, REASON_MISSING_ITEM_ANALYSIS } from '../../redux/modules/stockTake/types';
import useZoneBarcodeScan from '../common/hooks/useZoneBarcodeScan';
import ItemInventoryTaskForm from '../common/ItemInventoryTaskForm';
import ItemInventoryTaskItem from '../common/ItemInventoryTaskItem';
import { InventoryItemMenu, PaginationInterface } from '../common/ItemInventoryTaskList';
import ScanInput from '../common/ScanInput';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    subtitle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    progress: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    divider: {
      backgroundColor: '#ececec',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      marginLeft: 'auto',
    },
    accordionDetails: {
      padding: 0,
      flexDirection: 'column',
    },
    taskList: {
      width: '100%',
    },
    loadingModal: {
      display: 'flex',
    },
    loadingSpinner: {
      margin: 'auto',
    },
  });

interface ItemInventoryTaskListProps {
  bulkSetZeroAllowed?: boolean;
  tasks: ItemInventoryLocalTask[];
  inventoryLocation: [string, string];
  onListComplete: () => void;
}

interface ItemInventoryTaskListConnectedProps extends ItemInventoryTaskListProps, RouteComponentProps, WithStyles {
  setSnack: typeof setSnack;
}

// TODO: Mit ../common/ItemInventoryTaskList zusammenführen. Lediglich der onScan-Handler hat eine Ergänzung, und die "noch nicht bearbeitet" Liste wird ausgeblendet

const ItemInventoryTaskList = (props: ItemInventoryTaskListConnectedProps) => {
  const [showBulkUpdateConfirmation, setShowBulkUpdateConfirmation] = React.useState(false);
  const [isItemMenuOpen, setItemMenuOpen] = React.useState(false);
  const [isItemImageDialogOpen, setIsItemImageDialogOpen] = React.useState<boolean>(false);
  const [menuTask, setMenuTask] = React.useState<ItemInventoryLocalTask | undefined>(undefined);
  const [menuAnchor, setMenuAnchor] = React.useState<EventTarget | undefined>(undefined);
  const [taskFormItem, setTaskFormItem] = React.useState<ItemInventoryLocalTask | undefined>(undefined);
  const [previousTaskFormItem, setPreviousTaskFormItem] = React.useState<ItemInventoryLocalTask | null>(null);
  const [taskFormMode, setTaskFormMode] = React.useState<ItemInventoryStockUpdateMode>('add');
  // const [taskProgress, setTaskProgress] = React.useState(0);
  const [tasksWithCountings, setTasksWithCountings] = React.useState<ItemInventoryLocalTask[]>([]);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [tasksWithoutCountings, setTasksWithoutCountings] = React.useState<ItemInventoryLocalTask[]>([]);
  const [isBackgroundProcessActive, setBackgroundProcessActive] = React.useState(false);
  const [currentZone, scanZoneBarcode, resetZone] = useZoneBarcodeScan();

  const [paginationStateForTasksWithoutCounting, setPaginationStateForTasksWithoutCounting] =
    React.useState<PaginationInterface>({ currentPage: 0, qtyRows: 100 });
  const [paginationStateForTasksWithCounting, setPaginationStateForTasksWithCounting] =
    React.useState<PaginationInterface>({ currentPage: 0, qtyRows: 100 });

  const [qrCodeInformation, scanQrCode, getQrErrorNotification] = useQrCodeScan('', { allowScansAfterwards: false });
  const allowedToScanQrCode = usePermission({
    name: 'meeva_bis_iteminventory:physicalCount.itemCollectionList',
    canRead: true,
  });

  const { bulkSetZeroAllowed, classes, tasks, setSnack, inventoryLocation } = props;
  const scanLocation = currentZone || inventoryLocation[0];

  const confirmBulkUpdate = useCallback(() => {
    setShowBulkUpdateConfirmation(true);
  }, []);

  const addItemForInventory = useSelector((state) => getItemByProcess(state, 'item-inventory'));
  //TODO: Implement RootState (@meeva/service-client-core/redux/store) with store.getState types
  const isMissingItemAnalysis = AppMode.MISSING_ITEM_ANALYSIS_MODE === useSelector((state: any) => state.app.mode);

  const {
    quantityChangeLimit = {},
    checkPrevQtyConfirmed = false,
    sortTasksListByLastScan = false,
  } = AppConfig.getConfig().physicalCount ?? {};
  const { lowerLimit = null, upperLimit = null } = quantityChangeLimit;

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (qrCodeInformation?.error) {
      playErrorSound('others');
      dispatch(setSnack({ severity: 'warning', text: getQrErrorNotification() }));

      return;
    }

    if (qrCodeInformation?.id) {
      if (!allowedToScanQrCode) {
        dispatch(setSnack({ severity: 'error', text: 'Artikellisten QR-Code nur zur Warenverlagerung nutzbar' }));
      } else {
        (async () => {
          dispatch(showGlobalProgressModal(true));

          try {
            const itemListCollection: ItemCollectionListInterface | undefined = await getItemCollectionListById(
              qrCodeInformation.id!
            );

            if (itemListCollection) {
              for (const lineItem of itemListCollection.lineItems) {
                await createTaskByBarcode(null, lineItem.itemNo, 'replace', lineItem.quantity);
              }

              // Can be null as it is not use anymore if stock was modified
              setPreviousTaskFormItem(null);
            } else {
              dispatch(setSnack({ severity: 'error', text: 'Die Artikellisten wurde nicht gefunden' }));
            }
          } catch (e) {
            console.error(e);
            dispatch(setSnack({ severity: 'error', text: 'Es trat ein Fehler bei dem Scan der Artikelliste auf' }));
          } finally {
            dispatch(showGlobalProgressModal(false));
          }
        })();
      }
    }
  }, [qrCodeInformation]);

  React.useEffect(() => {
    resetZone();
  }, [inventoryLocation]);
  React.useEffect(() => {
    let sum = 0;
    const tempTasksWithCountings: ItemInventoryLocalTask[] = [];
    const tempTasksWithoutCountings: ItemInventoryLocalTask[] = [];
    tasks.forEach((task) => {
      const stockTakesInThisLocation = task.countings.filter((stockTake) => stockTake[0] === scanLocation);
      if (stockTakesInThisLocation.length === 0) {
        tempTasksWithoutCountings.push(task);
      } else {
        sum += stockTakesInThisLocation.reduce((acc, counting) => acc + counting[1].amount, 0);
        tempTasksWithCountings.push(task);
      }
    });

    if (sortTasksListByLastScan) {
      tempTasksWithCountings.sort((a, b) => b.countings?.[0]?.[1]?.timestamp - a.countings?.[0]?.[1]?.timestamp);
    }

    // const max = tempTasksWithCountings.length + tempTasksWithoutCountings.length;
    // setTaskProgress((max > 0 ? tempTasksWithCountings.length / max : 0) * 100);
    setTasksWithCountings(tempTasksWithCountings);
    if (AppConfig.getConfig()?.physicalCount?.zoneRequired || currentZone) {
      setTasksWithoutCountings([]);
    } else {
      setTasksWithoutCountings(tempTasksWithoutCountings);
    }
    setTotalCount(sum);
  }, [currentZone, scanLocation, tasks]);

  const closeItemMenu = useCallback(() => {
    setItemMenuOpen(false);
    setMenuTask(undefined);
    setMenuAnchor(undefined);
  }, [setItemMenuOpen, setMenuTask, setMenuAnchor]);

  const createTaskByBarcode = useCallback(
    async (event: any, code: string, countMode: 'replace' | 'add', amount: number | null = null) => {
      const item = await findItemByCode(code);

      if (item) {
        if (item.flags?.includes('noPhysicalCount')) {
          return {
            success: false,
            message: 'Diese Artikelnummer/EAN ist für die Inventur nicht erlaubt!',
          };
        }

        if (AppConfig.getConfig()?.physicalCount?.zoneRequired && !currentZone) {
          return {
            success: false,
            message: 'Es muss zuerst eine Zone gescannt werden.',
          };
        }

        closeItemMenu();

        const task = {
          id: mongoObjectId(),
          itemId: item.id,
          item,
          priority: 0,
          reason: isMissingItemAnalysis ? REASON_MISSING_ITEM_ANALYSIS : REASON_FREE_INVENTORY,
          shelfNumber: (item.shelfPlacements || [])[0]?.shelf?.number || -1,
          countings: [],
        };

        const willUpdateShelves = AppConfig.getConfig()?.itemInventory?.scanMode?.ifItemExistsCountUp || amount;
        await dispatch(addTasks({ tasks: [task], updateShelves: !willUpdateShelves }));

        if (AppConfig.getConfig()?.itemInventory?.scanMode?.ifItemExistsCountUp) {
          await modifyItemStock(task, 'replace', 1);
        } else if (amount) {
          await modifyItemStock(task, 'replace', amount);
        } else {
          if (!AppConfig.getConfig()?.itemInventory?.scanMode?.skipQuantityInputDialog) {
            setTaskFormItem(task);
            setTaskFormMode(countMode);
          }
        }
        return { success: true, message: '', task };
      }

      return {
        success: false,
        message: `Der Artikel mit dem Barcode "${code}" steht nicht auf dieser Inventurliste.`,
      };
    },
    [closeItemMenu, setTaskFormItem, setTaskFormMode, menuTask, currentZone]
  );

  React.useEffect(() => {
    if (addItemForInventory) {
      createTaskByBarcode(null, addItemForInventory.item, 'add');
      dispatch(removeItemToProcess(addItemForInventory));
    }
  });

  const editTask = useCallback(
    (event: any, task: ItemInventoryLocalTask | undefined, countMode: 'replace' | 'add') => {
      closeItemMenu();
      setTaskFormItem(task || menuTask);
      setTaskFormMode(countMode);
    },
    [closeItemMenu, setTaskFormItem, setTaskFormMode, menuTask]
  );

  const closeTaskForm = useCallback(() => {
    setTaskFormItem(undefined);
  }, [setTaskFormItem]);

  const modifyItemStock = useCallback(
    async (task: ItemInventoryLocalTask, mode: ItemInventoryStockUpdateMode, value: number) => {
      await dispatch(
        addStockTake(mode, {
          amount: value,
          itemId: task.itemId,
          timestamp: Date.now(),
          location: scanLocation,
          reason: isMissingItemAnalysis ? REASON_MISSING_ITEM_ANALYSIS : REASON_FREE_INVENTORY,
          status: StockTakeStatus.DRAFT,
        })
      );

      if (mode === 'reset' && (task.reason === REASON_FREE_INVENTORY || task.reason === REASON_MISSING_ITEM_ANALYSIS)) {
        await dispatch(removeTasksIfEmpty([task], false));
      }

      // Can be null as it is not use anymore if stock was modified
      setPreviousTaskFormItem(null);
    },
    [scanLocation, setSnack]
  );

  const bulkSetZero = useCallback(async () => {
    setShowBulkUpdateConfirmation(false);

    dispatch(
      addStockTake(
        'replace',
        tasksWithoutCountings.map((task) => {
          return {
            amount: 0,
            itemId: task.itemId,
            timestamp: Date.now(),
            location: scanLocation,
            reason: isMissingItemAnalysis ? REASON_MISSING_ITEM_ANALYSIS : REASON_FREE_INVENTORY,
            status: StockTakeStatus.DRAFT,
          };
        })
      )
    );

    // Can be null as it is not use anymore if stock was modified
    setPreviousTaskFormItem(null);
  }, [scanLocation, setSnack, tasksWithoutCountings]);

  const resetStockTake = useCallback(() => {
    if (!menuTask) {
      return;
    }

    closeItemMenu();
    modifyItemStock(menuTask, 'reset', 0);
  }, [menuTask, modifyItemStock, closeItemMenu]);

  const setStockToZero = useCallback(() => {
    if (!menuTask) {
      return;
    }

    modifyItemStock(menuTask, 'replace', 0);
    closeItemMenu();
  }, [menuTask, modifyItemStock, closeItemMenu]);

  const updateTaskCounting = useCallback(
    (value?: number) => {
      if (value === undefined || !taskFormItem) {
        closeTaskForm();

        window.setTimeout(() => {
          setSnack({
            severity: 'info',
            autoHideDuration: 3000,
            text: 'Es wurde kein Angabe erfasst',
          });
        }, 1);
        return;
      }

      modifyItemStock(taskFormItem, taskFormMode, value);
      closeTaskForm();
    },
    [closeTaskForm, modifyItemStock, taskFormItem, taskFormMode, setSnack]
  );

  const openItemMenu = useCallback(
    (target: EventTarget, task: ItemInventoryLocalTask) => {
      setItemMenuOpen(true);
      setMenuTask(task);
      setMenuAnchor(target);
    },
    [setItemMenuOpen, setMenuTask, setMenuAnchor]
  );

  const onScan = useCallback(
    async (code: string) => {
      try {
        if (scanZoneBarcode(code)) {
          playSuccessSound('others');
          return;
        }

        dispatch(showGlobalProgressModal(true));

        if (!Number(code)) {
          scanQrCode(code);
          dispatch(showGlobalProgressModal(false));
          return;
        }

        // check if code is contained in the current task list
        const codes = codeToSearchList(code);

        const task = tasks.find(
          (task) =>
            (codes.includes(task.item.itemNo) ||
              (task.item.customItemNo && codes.includes(task.item.customItemNo)) ||
              (task.item.scanCodes && task.item.scanCodes.filter((code) => codes.includes(code)).length > 0)) &&
            task.countings.length > 0
        );

        if (!task) {
          if (previousTaskFormItem) {
            playErrorSound('item');
            setSnack({
              autoHideDuration: 5000,
              severity: 'warning',
              text: `Bitte bestätige die Menge des vorherigen Artikels (${previousTaskFormItem.item.itemNo})`,
            });

            dispatch(showGlobalProgressModal(false));
            return;
          }

          const result = await createTaskByBarcode(null, code, 'replace');

          if (!result.success) {
            playErrorSound('item');
            setSnack({
              autoHideDuration: 5000,
              severity: 'warning',
              text: result.message,
            });
          } else {
            playSuccessSound('item');
            checkPrevQtyConfirmed && !previousTaskFormItem && setPreviousTaskFormItem(result!.task!);
          }

          dispatch(showGlobalProgressModal(false));
          return;
        }

        if (previousTaskFormItem?.itemId && previousTaskFormItem.itemId !== task.itemId) {
          playErrorSound('item');
          setSnack({
            autoHideDuration: 5000,
            severity: 'warning',
            text: `Bitte bestätige die Menge des vorherigen Artikels (${previousTaskFormItem.item.itemNo})`,
          });

          dispatch(showGlobalProgressModal(false));
          return;
        }

        checkPrevQtyConfirmed && setPreviousTaskFormItem(task);

        if (AppConfig.getConfig()?.itemInventory?.scanMode?.ifItemExistsCountUp) {
          let amount = 0;
          for (const counting of task.countings) {
            if (counting[0] === scanLocation) {
              amount = counting[1].amount;
            }
          }

          await modifyItemStock(task, 'replace', amount + 1);
        } else {
          editTask(null, task, 'add');
        }
      } finally {
        dispatch(showGlobalProgressModal(false));
        playSuccessSound('item');
      }
    },
    [editTask, createTaskByBarcode, tasks, setSnack, previousTaskFormItem, qrCodeInformation.id, scanLocation]
  );

  const openItemDetails = useCallback(() => {
    if (!menuTask) {
      closeItemMenu();
      return;
    }
    const posIdentity = menuTask.item.scanCodes.slice(0, 1);

    if (!posIdentity) {
      closeItemMenu();
      return;
    }

    history.push(`/itemInventory/item/details/byCode/${posIdentity}`);

    closeItemMenu();
  }, [menuTask, modifyItemStock, closeItemMenu]);

  const openItemImageDialog = useCallback(() => {
    if (!menuTask) {
      closeItemMenu();
      return;
    }

    setIsItemImageDialogOpen(true);
  }, [menuTask, setIsItemImageDialogOpen, closeItemMenu]);

  return (
    <Container>
      <Box mt={1}>
        <Paper>
          <Typography variant="h5" component="h2" className={classes.title}>
            {inventoryLocation[1]}
          </Typography>
          {currentZone && (
            <Typography variant="h6" component="h6" className={classes.subtitle}>
              Zone: {currentZone}
            </Typography>
          )}
          <Box className={classes.progress}>
            <ScanInput scanEvent={onScan} inputType="text" />
          </Box>
          <Box className={classes.progress}>
            {/* TODO: Mit ../common/ItemInventoryTaskList zusammenführen */}
            {/*
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={taskProgress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(taskProgress)}%`}</Typography>
            </Box>
            */}
            <Box width="100%" mr={1} mb={1} />
          </Box>

          {inventoryLocation[0] !== 'primary' || tasksWithoutCountings.length ? (
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Ungezählte Artikel</Typography>
                <Typography className={classes.secondaryHeading}>{tasksWithoutCountings.length} Artikel</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {bulkSetZeroAllowed && tasksWithoutCountings.length > 0 && (
                  <Box ml={2} mr={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={confirmBulkUpdate}
                      data-cy="free-inventory-count-btn-bulk-update"
                    >
                      Übrige Artikel haben keinen Bestand
                    </Button>
                  </Box>
                )}
                {Boolean(tasksWithoutCountings.length) && (
                  <TablePagination
                    component="div"
                    labelRowsPerPage="Zeilen:"
                    count={tasksWithoutCountings.length}
                    page={paginationStateForTasksWithoutCounting.currentPage}
                    rowsPerPage={paginationStateForTasksWithoutCounting.qtyRows}
                    onPageChange={(_, value) =>
                      setPaginationStateForTasksWithoutCounting((prevState) => ({
                        ...prevState,
                        currentPage: value,
                      }))
                    }
                    onRowsPerPageChange={(event) =>
                      setPaginationStateForTasksWithoutCounting((prevState) => ({
                        ...prevState,
                        qtyRows: Number(event.target.value) || 100,
                      }))
                    }
                  />
                )}
                <List component="nav" className={classes.taskList}>
                  {tasksWithoutCountings
                    .slice(
                      paginationStateForTasksWithoutCounting.currentPage *
                        paginationStateForTasksWithoutCounting.qtyRows,
                      paginationStateForTasksWithoutCounting.currentPage *
                        paginationStateForTasksWithoutCounting.qtyRows +
                        paginationStateForTasksWithoutCounting.qtyRows
                    )
                    .map((task, i) => (
                      <React.Fragment key={i}>
                        <ItemInventoryTaskItem
                          inventoryLocation={scanLocation}
                          task={task}
                          openMenu={openItemMenu}
                          onClick={openItemMenu}
                        />
                        {i < tasksWithoutCountings.length - 1 && <Divider className={classes.divider} component="li" />}
                      </React.Fragment>
                    ))}
                </List>
                <TablePagination
                  component="div"
                  labelRowsPerPage="Zeilen:"
                  count={tasksWithoutCountings.length}
                  page={paginationStateForTasksWithoutCounting.currentPage}
                  rowsPerPage={paginationStateForTasksWithoutCounting.qtyRows}
                  onPageChange={(_, value) =>
                    setPaginationStateForTasksWithoutCounting((prevState) => ({
                      ...prevState,
                      currentPage: value,
                    }))
                  }
                  onRowsPerPageChange={(event) =>
                    setPaginationStateForTasksWithoutCounting((prevState) => ({
                      ...prevState,
                      qtyRows: Number(event.target.value) || 100,
                    }))
                  }
                />
              </AccordionDetails>
            </Accordion>
          ) : (
            false
          )}

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Bereits gezählt</Typography>
              <Typography className={classes.secondaryHeading}>
                {tasksWithCountings.length} Artikel
                {tasksWithCountings.length > 0 && ` / ${totalCount} Stück`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {Boolean(tasksWithCountings.length) && (
                <TablePagination
                  component="div"
                  labelRowsPerPage="Zeilen:"
                  count={tasksWithCountings.length}
                  page={paginationStateForTasksWithCounting.currentPage}
                  rowsPerPage={paginationStateForTasksWithCounting.qtyRows}
                  onPageChange={(_, value) =>
                    setPaginationStateForTasksWithCounting((prevState) => ({
                      ...prevState,
                      currentPage: value,
                    }))
                  }
                  onRowsPerPageChange={(event) =>
                    setPaginationStateForTasksWithCounting((prevState) => ({
                      ...prevState,
                      qtyRows: Number(event.target.value) || 100,
                    }))
                  }
                />
              )}
              <List component="nav" className={classes.taskList}>
                {tasksWithCountings
                  .slice(
                    paginationStateForTasksWithCounting.currentPage * paginationStateForTasksWithCounting.qtyRows,
                    paginationStateForTasksWithCounting.currentPage * paginationStateForTasksWithCounting.qtyRows +
                      paginationStateForTasksWithCounting.qtyRows
                  )
                  .map((task, i) => {
                    return (
                      <React.Fragment key={i}>
                        <ItemInventoryTaskItem
                          inventoryLocation={scanLocation}
                          task={task}
                          openMenu={openItemMenu}
                          onClick={openItemMenu}
                        />
                        {i < tasksWithCountings.length - 1 && <Divider className={classes.divider} component="li" />}
                      </React.Fragment>
                    );
                  })}
              </List>
              <TablePagination
                component="div"
                labelRowsPerPage="Zeilen:"
                count={tasksWithCountings.length}
                page={paginationStateForTasksWithCounting.currentPage}
                rowsPerPage={paginationStateForTasksWithCounting.qtyRows}
                onPageChange={(_, value) =>
                  setPaginationStateForTasksWithCounting((prevState) => ({
                    ...prevState,
                    currentPage: value,
                  }))
                }
                onRowsPerPageChange={(event) =>
                  setPaginationStateForTasksWithCounting((prevState) => ({
                    ...prevState,
                    qtyRows: Number(event.target.value) || 100,
                  }))
                }
              />
            </AccordionDetails>
          </Accordion>

          <InventoryItemMenu
            open={isItemMenuOpen}
            anchorEl={menuAnchor}
            onClose={closeItemMenu}
            onAdd={() => editTask(null, undefined, 'add')}
            onEdit={() => editTask(null, undefined, 'replace')}
            onReset={resetStockTake}
            onSetStockToZero={setStockToZero}
            onItemDetails={openItemDetails}
            onItemImageDisplay={openItemImageDialog}
            menuTask={menuTask}
          />

          {taskFormItem !== undefined && (
            <ItemInventoryTaskForm
              task={taskFormItem}
              mode={taskFormMode}
              inventoryLocation={inventoryLocation}
              onClose={closeTaskForm}
              onSave={updateTaskCounting}
              {...(lowerLimit && { lowerStockLimit: lowerLimit })}
              {...(upperLimit && { upperStockLimit: upperLimit })}
            />
          )}

          <Dialog open={showBulkUpdateConfirmation}>
            <DialogTitle>{'Prüfung'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Bist du sicher, dass die verbleibenden <strong>{tasksWithoutCountings.length} Artikel</strong> mit einem{' '}
                <strong>Bestand von 0</strong> gebucht werden sollen?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowBulkUpdateConfirmation(false)} color="primary">
                Abbrechen
              </Button>
              <Button onClick={bulkSetZero} color="primary" autoFocus>
                Ja, fortfahren
              </Button>
            </DialogActions>
          </Dialog>
          {menuTask?.item && (
            <ItemImageDialog
              open={isItemImageDialogOpen}
              onClose={() => {
                setIsItemImageDialogOpen(false);
                closeItemMenu();
              }}
              item={menuTask.item}
            />
          )}
        </Paper>
      </Box>

      <Box mt={2} mb={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={props.onListComplete}
          disabled={
            tasksWithCountings.length === 0 &&
            (!AppConfig.getConfig()?.physicalCount?.zoneRequired || tasks.length === 0)
          }
          data-cy="free-inventory-count-btn-confirm"
        >
          {inventoryLocation[1]} abschließen
        </Button>
      </Box>

      <Modal
        disableBackdropClick
        disableEnforceFocus
        disableAutoFocus
        className={classes.loadingModal}
        open={isBackgroundProcessActive}
        onClose={() => setBackgroundProcessActive(false)}
      >
        <CircularProgress className={classes.loadingSpinner} />
      </Modal>
    </Container>
  );
};

export default connect(null, {
  setSnack,
})(withRouter(withStyles(styles)(ItemInventoryTaskList))) as React.ComponentType<ItemInventoryTaskListProps>;
