import DoneAllIcon from '@material-ui/icons/DoneAll';
import * as React from 'react';

export default [
  {
    id: 'Inventur',
    title: 'Inventur',
    icon: <DoneAllIcon />,
    route: '/itemInventory',
    requiredPermission: {
      name: 'meeva_bis_iteminventory:stockTake',
      canRead: true,
      canWrite: true,
    },
  },
];
