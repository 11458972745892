import gql from 'graphql-tag';
import { ThunkDispatch } from 'redux-thunk';

import { setSnack, showGlobalProgressModal } from '../../../../redux/interface/actions';
import {
  resetAvailableBusinessUnitGroups,
  setAvailableBusinessUnitGroups,
  setIsAuthenticated,
  setSessionInfo,
} from '../../../../redux/security/actions';
import { resetAppContent } from '../../../../redux/security/operations';
import { AUTHENTICATING } from '../../../../redux/security/types';
import GraphQLClient from '../dataProvider/apiClient';
import { DB } from '../dataProvider/storageClient';

enum ConfigurationTableKeys {
  information = 'session/information',
  lastBusinessUnit = 'general/lastBusinessUnitGroup',
  apiToken = 'api/token',
}

export const AUTH = {
  LOGIN: 'login',
  CHECK: 'check',
  VALIDATE_UNIT: 'validateUnit',
  ERROR: 'error',
  LOGOUT: 'logout',
};

const SessionQuery = gql`
  query {
    viewer {
      username
      remoteAddress
      permissions {
        name
        canRead
        canWrite
        canDelete
        canUndelete
      }
      businessUnitGroup {
        id
        name
        unit {
          id
          name
          number
          statusCode
        }
      }
      availableBusinessUnitGroups {
        id
        name
        unit {
          id
          name
          number
          statusCode
        }
      }
    }
  }
`;

export interface SessionPermission {
  name: string;
  canRead: boolean;
  canWrite: boolean;
  canDelete: boolean;
  canUndelete: boolean;
}

export type SessionPermissionRequest = Partial<Omit<SessionPermission, 'name'>> & Pick<SessionPermission, 'name'>;

export interface SessionQueryBusinessUnitGroupInterface {
  id: string;
  name: string;
  unit: {
    id: string;
    name: string;
    number: number;
    statusCode: 'active' | 'inactive';
  };
}

interface PartialSessionQueryResult {
  username: string;
  remoteAddress: string;
  permissions: SessionPermission[];
  businessUnitGroup: SessionQueryBusinessUnitGroupInterface | null;
  availableBusinessUnitGroups: SessionQueryBusinessUnitGroupInterface[];
}

export interface SessionQueryResult extends PartialSessionQueryResult {
  businessUnitGroup: SessionQueryBusinessUnitGroupInterface;
}

const authSuccessful =
  (session: SessionQueryResult, showGreeting = true) =>
  (dispatch: ThunkDispatch<any, null, any>) => {
    dispatch({
      type: AUTHENTICATING,
      inProgress: false,
    });
    dispatch(showGlobalProgressModal(false));

    if (!session.businessUnitGroup) {
      throw Error('BusinessUnitGroup missing');
    }

    if (showGreeting) {
      dispatch(
        setSnack({
          text: `Willkommen in Filiale ${session.businessUnitGroup.unit.number}!`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      );
    }

    dispatch(setSessionInfo(session));
    dispatch(setIsAuthenticated());
  };

const authFailed = (reason: string) => (dispatch: ThunkDispatch<any, null, any>) => {
  dispatch({
    type: AUTHENTICATING,
    inProgress: false,
  });
  dispatch(showGlobalProgressModal(false));

  switch (reason) {
    case 'unknown_location':
      dispatch(
        setSnack({
          autoHideDuration: 10000,
          severity: 'error',
          text: 'Unbekannter Standort. Bitte wenden Sie sich an die IT.',
        })
      );
      return;

    case 'invalid_credentials':
      dispatch(
        setSnack({
          autoHideDuration: 5000,
          severity: 'error',
          text: 'Ungültige Zugangsdaten.',
        })
      );
      return;

    case 'server_error':
      dispatch(
        setSnack({
          autoHideDuration: 5000,
          severity: 'error',
          text: 'Aufgrund einer Störung ist die Anmeldung momentan nicht möglich. Bitte versuchen Sie es später erneut.',
        })
      );
      return;

    case 'unknown':
      dispatch(
        setSnack({
          autoHideDuration: 5000,
          severity: 'error',
          text: 'Anmeldung momentan nicht möglich. Bitte versuchen Sie es später erneut.',
        })
      );
      return;
    case 'no_token':
  }
};

const authProvider = (type: string, params?: { [key: string]: any }) => (dispatch: ThunkDispatch<any, null, any>) => {
  switch (type) {
    case AUTH.CHECK:
      return DB.configuration
        .bulkGet([
          ConfigurationTableKeys.apiToken,
          ConfigurationTableKeys.information,
          ConfigurationTableKeys.lastBusinessUnit,
        ])
        .then(([token, session, lastBusinessUnitGroup]) => {
          if (!token || !session || !session.value.businessUnitGroup || !lastBusinessUnitGroup) {
            return dispatch(authFailed('no_token'));
          }

          return dispatch(authSuccessful(session.value));
        });

    case AUTH.ERROR:
      if (!params) {
        throw Error('Params missing');
      }
      if (
        params.networkError &&
        params.networkError.response &&
        [401, 403].includes(params.networkError.response.status)
      ) {
        return DB.configuration.delete(ConfigurationTableKeys.apiToken).finally(() => {
          throw new Error('Authentication error');
        });
      }

      console.error('AUTH_ERROR', type, params);

      return Promise.resolve();

    case AUTH.LOGOUT: {
      dispatch(resetAvailableBusinessUnitGroups());

      return DB.configuration.bulkDelete([ConfigurationTableKeys.apiToken, ConfigurationTableKeys.information]);
    }

    case AUTH.VALIDATE_UNIT: {
      if (!params) {
        throw Error('Params missing');
      }

      (async () => {
        const lastBusinessUnitGroup = (await DB.configuration.get(ConfigurationTableKeys.lastBusinessUnit))?.value;

        if (lastBusinessUnitGroup && lastBusinessUnitGroup.id !== params.businessUnitGroup.id) {
          await dispatch(resetAppContent());
        }

        const previousSession = (await DB.configuration.where('key').equals(ConfigurationTableKeys.information).first())
          ?.value;

        const session = { ...previousSession, businessUnitGroup: params.businessUnitGroup };

        await DB.configuration.bulkPut([
          { key: ConfigurationTableKeys.information, value: session },
          { key: ConfigurationTableKeys.lastBusinessUnit, value: params.businessUnitGroup },
        ]);

        return dispatch(authSuccessful(session, true));
      })();

      return Promise.resolve();
    }

    case AUTH.LOGIN: {
      if (!params) {
        throw Error('Params missing');
      }

      const { username, password } = params;

      const request = new Request(process.env.REACT_APP_API_AUTH_URL!, {
        method: 'post',
        body: JSON.stringify({ username, password }),
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-App-Version': process.env.REACT_APP_VERSION_HASH || 'dev',
        }),
      });

      return fetch(request)
        .then(async (response) => {
          if (response.status < 200 || response.status > 299) {
            throw response.status;
          }

          return response.json();
        })
        .then(async ({ token }) => {
          // Token für spätere Verwendung speichern
          await DB.configuration.put({
            key: ConfigurationTableKeys.apiToken,
            value: token,
          });
        })
        .then(async () => {
          // Session Daten abrufen
          const client = await GraphQLClient();
          const session = await client.query<{ viewer: PartialSessionQueryResult }>({
            fetchPolicy: 'no-cache',
            query: SessionQuery,
          });

          return session.data.viewer;
        })
        .then(async (querySession) => {
          if (!querySession.availableBusinessUnitGroups || !querySession.availableBusinessUnitGroups.length) {
            // Sessiondaten löschen
            await DB.configuration
              .where('key')
              .anyOf([ConfigurationTableKeys.apiToken, ConfigurationTableKeys.information])
              .delete();

            return dispatch(authFailed('unknown_location'));
          }

          let reloadApp = false;
          let newSession = querySession;

          if (querySession.availableBusinessUnitGroups.length === 1) {
            newSession = { ...querySession, businessUnitGroup: querySession.availableBusinessUnitGroups.at(0)! };
          }

          await DB.configuration.put({ key: ConfigurationTableKeys.information, value: newSession });

          if (newSession.availableBusinessUnitGroups.length > 1) {
            // Show store selection if there is more than one business unit
            dispatch(showGlobalProgressModal(false));
            return dispatch(setAvailableBusinessUnitGroups(newSession.availableBusinessUnitGroups));
          }

          // prüfen ob wir eine früherer Filiale kennen und diese abweicht
          const lastBusinessUnitGroup = await DB.configuration.get(ConfigurationTableKeys.lastBusinessUnit);
          if (lastBusinessUnitGroup && lastBusinessUnitGroup.value.id !== newSession.businessUnitGroup?.id) {
            // Filiale gewechselt, filial-spezifische Daten resetten
            await dispatch(resetAppContent());
            reloadApp = true;
          }

          // separat speichern um Filialwechsel zu erkennen
          await DB.configuration.put({
            key: ConfigurationTableKeys.lastBusinessUnit,
            value: newSession.businessUnitGroup,
          });

          if (reloadApp) {
            window.location.reload();
            return;
          }

          return dispatch(authSuccessful(newSession as SessionQueryResult));
        })
        .catch((e) => {
          if (e === 401) {
            return dispatch(authFailed('invalid_credentials'));
          } else if (e >= 500 && e < 600) {
            return dispatch(authFailed('server_error'));
          }

          return dispatch(authFailed('unknown'));
        });
    }

    default:
  }

  return Promise.resolve();
};

export default authProvider;
