import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';
import _ from 'lodash';

import { ItemInventoryShelf, ItemInventoryTask } from '../../interfaces/ItemInventoryInterface';
import { DB } from '../../modules/app/client/dataProvider/storageClient';
import AbstractSyncLoader, {
  maxUpdatedAt,
  RunResult,
  RunResultStatus,
  SyncResetReason,
} from '../../modules/common/serviceWorker/AbstractSyncLoader';
import { getLocations } from '../../utils/inventoryTaskHelper';
import WebWorkerConfig from '../config';

interface QueryVariables {
  businessUnitId: string;
}

export default class InventoryTasksLoader extends AbstractSyncLoader {
  protected schemaVersion = 5;
  private insertBatchSize = 100;

  private static async cleanUpGraphItems(tasks: any[]) {
    const deleteTasks = tasks.filter((task) => task.completedAt || task.deletedAt).map((task) => task.id);
    const locations = getLocations();
    const { showBrandInventory = false } = WebWorkerConfig.getConfig().itemInventory ?? {};

    let deleteStockTakeResults: any = [];
    if (showBrandInventory) {
      deleteStockTakeResults = tasks
        .filter((task) => task.completedAt || task.deletedAt)
        .filter((task) => task.reason === 'MAN')
        .flatMap((task) => [
          [task.item.id, task.item?.brand?.name || ''],
          [task.item.id, locations.keys().next().value],
        ]);
    }

    const upsertTasks: ItemInventoryTask[] = await Promise.all(
      tasks
        .filter((task) => !task.completedAt && !task.deletedAt)
        .map(async (task) => {
          const item = await DB.items.get(task.item.id);

          return {
            id: task.id,
            itemId: task.item.id,
            item: item!,
            priority: task.priority,
            reason: task.reason,
            comment: task.comment,
            brandName: task.item.brand ? task.item.brand.name : 'Unbekannt',
            shelfNumber: item && (item.shelfPlacements || []).length > 0 ? item.shelfPlacements[0].shelf.number : -1,
          };
        })
    );

    //Alle neuen Manuellen Inventuraufgaben müssen die freien Scans bereinigen
    for (const upsertTask of upsertTasks) {
      if (upsertTask.reason === 'MAN') {
        deleteStockTakeResults.push([upsertTask.item.id, locations.keys().next().value]);
      }
    }

    return {
      deleteTasks,
      deleteStockTakeResults,
      upsertTasks,
    };
  }
  private variables: QueryVariables | undefined;
  private query = gql`
    query ($query: InputListArguments, $businessUnitId: ID!) {
      inventoryTasks(businessUnit: $businessUnitId, query: $query) {
        total
        nodes {
          id
          item {
            id
            brand {
              id
              name
            }
          }
          reason
          priority
          completedAt
          deletedAt
          updatedAt
        }
      }
    }
  `;

  constructor() {
    super('itemInventory');
  }

  public async init(): Promise<void> {
    await super.init();

    this.variables = {
      businessUnitId: this.session.businessUnitGroup.unit.id,
    };

    const lastReset = await DB.configuration.get({
      key: 'sync/itemInventory/lastReset',
    });

    // Jeden Tag diese Daten vollständig neu synchronisieren
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);

    if (!lastReset || new Date(lastReset.value) < startOfDay) {
      await this.reset(SyncResetReason.CLEANUP);
      await DB.configuration.put({
        key: `sync/${this.typeName}/lastReset`,
        value: new Date().toISOString(),
      });
    }
  }

  protected async reset(reason: SyncResetReason = SyncResetReason.UNKNOWN): Promise<void> {
    await super.reset(reason);

    await Promise.all([DB.inventoryTasks.clear(), DB.inventoryTasksByShelves.clear()]);
  }

  protected async run(prevRunResult?: RunResult): Promise<RunResult> {
    try {
      const result = await this.client.query({
        fetchPolicy: 'no-cache',
        query: this.query,
        variables: {
          ...this.variables,
          query: this.getPaginationQueryArgs(prevRunResult),
        },
      });

      const inventoryTasks = result.data.inventoryTasks;
      const totalCount = inventoryTasks.total;
      const resultCount = inventoryTasks.nodes.length;

      if (resultCount === 0) {
        await InventoryTasksLoader.updateShelves([]);

        return {
          status: RunResultStatus.FINISHED,
          progress: {
            records: {
              remaining: totalCount - resultCount,
              processed: resultCount,
            },
          },
          additionalInfo: {
            'Anzahl Aufgaben': await DB.inventoryTasks.count(),
            'Anzahl Regale': await DB.inventoryTasksByShelves.count(),
          },
        };
      }

      const insertNodes = _.cloneDeep(inventoryTasks.nodes);
      while (insertNodes.length > 0) {
        const newBatchSize = await this.slowDownTracker(
          (async () => {
            const { deleteTasks, deleteStockTakeResults, upsertTasks } =
              await InventoryTasksLoader.cleanUpGraphItems(insertNodes);

            // Einträge löschen
            await DB.inventoryTasks.bulkDelete(deleteTasks);

            // Zählungen löschen
            await DB.stockTakeResults.bulkDelete(deleteStockTakeResults);

            // neue Einträge anlegen
            await DB.inventoryTasks.bulkPut(upsertTasks);

            // Regale aktualisieren
            await InventoryTasksLoader.updateShelves(insertNodes);
          })(),
          this.insertBatchSize
        );

        insertNodes.splice(0, this.insertBatchSize);
        this.insertBatchSize = newBatchSize;
      }

      return {
        status: resultCount === this.batchSize ? RunResultStatus.SUCCESS : RunResultStatus.FINISHED,
        progress: {
          lastUpdate: maxUpdatedAt(inventoryTasks.nodes),
          lastId: inventoryTasks.nodes[resultCount - 1].id,
          records: {
            remaining: totalCount - resultCount,
            processed: resultCount,
          },
        },
        additionalInfo: {
          'Anzahl Aufgaben': await DB.inventoryTasks.count(),
          'Anzahl Regale': await DB.inventoryTasksByShelves.count(),
        },
        checkpointData: [
          {
            key: 'sync/itemInventory/lastUpdate',
            value: inventoryTasks.nodes[resultCount - 1].updatedAt,
          },
          {
            key: 'sync/itemInventory/lastId',
            value: inventoryTasks.nodes[resultCount - 1].id,
          },
        ],
      };
    } catch (e) {
      if (e instanceof Error && e.message.includes('401')) {
        return {
          status: RunResultStatus.UNAUTHORIZED,
        };
      }

      Sentry.captureException(e);
      console.error(e);
      return {
        status: RunResultStatus.ERROR,
        additionalInfo: {
          'Anzahl Aufgaben': await DB.inventoryTasks.count(),
          'Anzahl Regale': await DB.inventoryTasksByShelves.count(),
        },
      };
    }
  }

  public static async updateShelves(tasks: { item: { id: string } }[]) {
    const _shelvesToUpdate = await Promise.all(
      tasks.map(async (task) => {
        const item = await DB.items.get(task.item.id);
        const shelf = item && (item.shelfPlacements || []).length > 0 ? item.shelfPlacements[0].shelf : undefined;

        return (
          shelf || {
            number: -1,
            name: 'Aktionsware',
          }
        );
      })
    );

    const shelvesToUpdate: Map<number, { number: number; name: string }> = new Map();
    _shelvesToUpdate.forEach((shelf) => {
      shelvesToUpdate.set(shelf.number, shelf);
    });

    return DB.transaction('rw', DB.inventoryTasksByShelves, DB.inventoryTasks, async () => {
      const updatePromises = Array.from(shelvesToUpdate.values()).map(async ({ number, name }) => {
        // alle Aufgaben für dieses Regal sammeln
        const tasks = await DB.inventoryTasks.where('shelfNumber').equals(number).toArray();
        const shelf: ItemInventoryShelf = {
          number,
          name,
          tasks,
          taskIds: tasks.map((task) => task.id),
          taskItemIds: tasks.map((task) => task.itemId),
        };

        // Regal speichern
        await DB.inventoryTasksByShelves.put(shelf);
      });

      await Promise.all(updatePromises);

      // leere Regale löschen
      await DB.inventoryTasksByShelves.filter((shelf) => !shelf.tasks || shelf.tasks.length === 0).delete();
    });
  }
}
