import AppConfig from '@meeva/service-client-core/modules/app/config';
import { useCallback, useState } from 'react';

const useZoneBarcodeScan = () => {
  const [currentZone, setCurrentZone] = useState<string | undefined>(undefined);

  const scanBarcode = useCallback(
    (barcode: string) => {
      const zoneBarcodeRe = AppConfig.getConfig()?.physicalCount?.zoneBarcode;
      if (zoneBarcodeRe) {
        const match = barcode.match(zoneBarcodeRe);
        if (match) {
          setCurrentZone(match[1] || match[0]);
          return match[1] || match[0];
        }
      }
      return null;
    },
    [setCurrentZone]
  );
  const resetZone = useCallback(() => setCurrentZone(undefined), [setCurrentZone]);

  return [currentZone, scanBarcode, resetZone] as const;
};

export default useZoneBarcodeScan;
