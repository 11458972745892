import { ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import ShelfDifferences from './ShelfDifferences';
import PlannedShelves from './ShelfList';
import ShelfSummary from './ShelfSummary';
import ShelfTasks from './ShelfTasks';

interface ItemInventoryPlannedProps {
  tasks: [number, ShelfListing][];
}

const ItemInventoryPlanned = (props: ItemInventoryPlannedProps & RouteComponentProps) => {
  return (
    <Switch>
      <Route
        path={`/itemInventory/type/:type/shelf/:shelfId/differences`}
        exact
        render={() => <ShelfDifferences {...props} />}
      />
      <Route path="/itemInventory/type/:type/shelf/:shelfId/summary" exact render={() => <ShelfSummary {...props} />} />
      <Route
        path="/itemInventory/type/:type/shelf/:shelfId"
        exact
        render={(routeProps) => <ShelfTasks {...props} {...routeProps} />}
      />
      <Route
        path="/itemInventory/type/:type/shelf/:shelfId/location/:inventoryLocationName"
        exact
        render={() => <ShelfTasks {...props} />}
      />
      <Route
        path="/itemInventory/type/:type"
        exact
        render={(routeProps) => <PlannedShelves {...props} {...routeProps} />}
      />
    </Switch>
  );
};

export default connect()(withRouter(ItemInventoryPlanned)) as React.ComponentType<ItemInventoryPlannedProps>;
