import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, ReducersMapObject } from 'redux';

import AppModeSlice from './appBehavior/appModeSlice';
import * as AppReducer from './common/app/reducers';
import * as ScannerInputReducer from './common/components/ScannerInput/reducers';
import * as eventsReducer from './event/reducer';
import * as interfaceReducers from './interface/reducers';
import * as itemsDetailReducer from './items/detail/reducers';
import * as securityReducer from './security/reducers';

const createRootReducer = (history: History, moduleReducers: ReducersMapObject) =>
  combineReducers({
    app: combineReducers({ ...AppReducer, mode: AppModeSlice }),
    router: connectRouter(history),
    interface: combineReducers(interfaceReducers),
    security: combineReducers(securityReducer),
    scannerInput: combineReducers(ScannerInputReducer),
    itemsDetail: combineReducers(itemsDetailReducer),
    events: combineReducers(eventsReducer),
    ...moduleReducers,
  });
export default createRootReducer;
