import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';

interface Props {
  open: boolean;
  title: string;
  contentText: string;
  cancelText?: string;
  confirmText: string;
  disabledConfirm?: boolean;
  handleClose: () => any;
  handleConfirm: () => any;
  children?: React.ReactNode;
}

const ConfirmDialog = ({
  open,
  handleClose,
  handleConfirm,
  title,
  contentText,
  cancelText,
  confirmText,
  children,
  disabledConfirm,
}: Props) => (
  <Dialog open={open} onClose={handleClose} data-cy={`dialog-confirm`}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{contentText}</DialogContentText>
      {children}
    </DialogContent>
    <DialogActions>
      {cancelText && (
        <Button variant="contained" color="default" onClick={handleClose}>
          {cancelText}
        </Button>
      )}
      <Button
        disabled={disabledConfirm ? disabledConfirm : false}
        variant="contained"
        color="primary"
        onClick={handleConfirm}
      >
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
