import get from 'lodash/get';
import { Route } from 'react-router';

import { Config } from '../../interfaces/AppInterface';
import { CategoryChildInterface } from './layout/navigator';

export class AppConfig {
  private config: Config = {
    logo: null,
  };

  private routes: Route[] = [];

  private navigation: CategoryChildInterface[] = [];

  private components: any = {};

  private serviceWorker: string[] = [];

  public addRoute(route: Route) {
    this.routes.push(route);
  }

  public getRoutesMap(): Route[] {
    return this.routes;
  }

  public addNavigation(navigation: CategoryChildInterface) {
    this.navigation.push(navigation);
  }

  public getNavigationMap(): CategoryChildInterface[] {
    return this.navigation;
  }

  public getNavigationRoutes(): string[] {
    const routes = [];
    for (const navi of this.navigation) {
      if (navi.route) {
        routes.push(navi.route);
      }
    }
    return routes;
  }

  public setConfig(config: Config) {
    this.config = { ...this.config, ...config };
  }

  public getConfig(): Config {
    return this.config;
  }

  public getConfigOption(option: string): any {
    return get(this.config, option);
  }

  public setComponent(name: string, component: any) {
    this.components[name] = component;
  }

  public getComponent(name: string) {
    return this.components[name];
  }

  public addServiceWorker(serviceWorker: string) {
    this.serviceWorker.push(serviceWorker);
  }

  public getServiceWorker() {
    return this.serviceWorker;
  }
}

export default new AppConfig();
