import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSnack } from '../../../redux/interface/actions';
import { getGlobalProgressModal } from '../../../redux/interface/selectors';

export type SupportedCodeCheck = (code: string) => boolean;
type ScannerSupportedCode = SupportedCodeCheck | RegExp;

export const ScannerSupportedCodes = {
  EAN13: /^(\d{13})$/,
  EAN8: /^(\d{8})$/,
  PLU3: /^(\d{3})$/,
  UPC12: /^(\d{12})$/,
  ITF: /^((\d\d)+)$/,
};
export const DEFAULT_SUPPORTED_CODES = [
  ScannerSupportedCodes.EAN13,
  ScannerSupportedCodes.EAN8,
  ScannerSupportedCodes.PLU3,
  ScannerSupportedCodes.UPC12,
  ScannerSupportedCodes.ITF,
];

export interface ScannerInputProps {
  supportedCodes?: ScannerSupportedCode[];
  disabled?: boolean;
  onScan?: (barcode: string) => void;
  timeout?: number;
}

const isSupportedCode = (code: string, supportedCodes: ScannerSupportedCode[]) =>
  supportedCodes.length === 0 ||
  supportedCodes.some((supportedCode) =>
    supportedCode instanceof RegExp ? supportedCode.test(code) : supportedCode(code)
  );

const ScannerInput = ({ supportedCodes = [], disabled = false, timeout = 1000, onScan }: ScannerInputProps) => {
  const [inputBarcode, setInputBarcode] = React.useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getGlobalProgressModal(state).visible);

  const debouncedTimeout = React.useCallback(
    (timeout: number | undefined) => {
      if (timeout) {
        return debounce(() => setInputBarcode(''), timeout);
      }

      return debounce(() => {}, 0);
    },
    [timeout, setInputBarcode]
  )(timeout);

  const onKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.target && (e.target as Node).nodeName.toLowerCase() === 'input') {
        return;
      }

      if (e.key === 'Enter' && inputBarcode.length > 0) {
        if (disabled || isLoading) {
          setInputBarcode('');

          return dispatch(
            setSnack({
              text: 'Ein Artikel-Scan ist derzeitig nicht möglich',
              severity: 'warning',
              autoHideDuration: 5000,
            })
          );
        }

        const valid = isSupportedCode(inputBarcode, supportedCodes);

        if (valid && onScan) {
          onScan && onScan(inputBarcode);
        }

        debouncedTimeout.cancel();
        setInputBarcode('');

        e.preventDefault();
        e.stopPropagation();

        return false;
      }

      debouncedTimeout();
      const input = e.key.length === 1 ? e.key : undefined;
      if (input) {
        setInputBarcode((prevState: string) => prevState + input);
      }
    },
    [setInputBarcode, inputBarcode, supportedCodes, onScan, disabled, isLoading]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, {
      capture: true,
      passive: false,
    });

    return () => {
      document.removeEventListener('keydown', onKeyDown, {
        capture: true,
      });
    };
  }, [onKeyDown]);

  return null;
};

export default ScannerInput;
