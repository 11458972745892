import {
  Box,
  Button,
  Container,
  Divider,
  List,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import { ItemInventoryLocalTask, StockTakeStatus } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addStockTake, removeTasks } from '../../redux/modules/stockTake/operations';
import ItemInventoryTaskItem from '../common/ItemInventoryTaskItem';
import { TaskInput } from './TaskInput';

interface ZoneCountListProps {
  tasks: ItemInventoryLocalTask[];
}

const InventoryItemMenu = ({ open, onClose, onEdit, onReset, anchorEl }: any) => {
  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      <MenuItem onClick={onEdit}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        Bearbeiten
      </MenuItem>
      <MenuItem onClick={onReset}>
        <ListItemIcon>
          <HistoryIcon fontSize="small" />
        </ListItemIcon>
        Zurücksetzen
      </MenuItem>
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  divider: {
    backgroundColor: '#ececec',
  },
  taskList: {
    width: '100%',
  },
}));

const ZoneCountList = (props: ZoneCountListProps) => {
  const { tasks } = props;
  const dispatch = useDispatch();
  // const scanLocation = currentZone || inventoryLocation[0];
  const classes = useStyles();

  const [[menuTask, menuAnchor] = [undefined, undefined], setMenuTask] = React.useState<
    [ItemInventoryLocalTask, EventTarget] | undefined
  >(undefined);
  const openItemMenu = useCallback(
    (target: EventTarget, task: ItemInventoryLocalTask) => setMenuTask([task, target]),
    [setMenuTask]
  );
  const closeItemMenu = useCallback(() => setMenuTask(undefined), [setMenuTask]);
  const [taskFormItem, setTaskFormItem] = React.useState<ItemInventoryLocalTask | undefined>(undefined);

  const resetStockTake = useCallback(async () => {
    if (!menuTask) {
      return;
    }

    closeItemMenu();
    await dispatch(removeTasks([menuTask], false));
    await dispatch(
      addStockTake('reset', {
        amount: 0,
        itemId: menuTask!.itemId,
        timestamp: Date.now(),
        location: menuTask.countings[0]?.[0] || '',
        reason: menuTask.reason,
        status: StockTakeStatus.DRAFT,
      })
    );
  }, [menuTask, dispatch, closeItemMenu]);
  const editTask = useCallback(() => {
    closeItemMenu();
    setTaskFormItem(menuTask);
  }, [closeItemMenu, setTaskFormItem, menuTask]);

  const onListComplete = useCallback(() => {
    dispatch(push(`/itemInventory/zoneCount/summary`));
  }, [dispatch]);

  return (
    <Container>
      <Box mt={1}>
        <Paper>
          {/*<Typography variant="h5" component="h2" className={classes.title}>*/}
          {/*  {inventoryLocation[1]}*/}
          {/*</Typography>*/}
          <TaskInput taskFormItem={taskFormItem} setTaskFormItem={setTaskFormItem} />
        </Paper>
      </Box>
      <InventoryItemMenu
        open={menuTask !== undefined}
        anchorEl={menuAnchor}
        onClose={closeItemMenu}
        onEdit={editTask}
        onReset={resetStockTake}
        menuTask={menuTask}
      />

      <Box mt={2} mb={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onListComplete}
          disabled={tasks.length === 0}
          data-cy="free-inventory-count-btn-confirm"
        >
          Abschließen
        </Button>
      </Box>
      <Paper>
        <List component="nav" className={classes.taskList}>
          {tasks.map((task, i) => {
            return (
              <React.Fragment key={i}>
                <ItemInventoryTaskItem
                  inventoryLocation={task.countings[0]?.[0] || ''}
                  task={task}
                  openMenu={openItemMenu}
                  onClick={openItemMenu}
                />
                {i < tasks.length - 1 && <Divider className={classes.divider} component="li" />}
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
    </Container>
  );
};

export default ZoneCountList;
