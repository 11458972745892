import { ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import FreeTasks from './FreeTasks';
import TasksDifferences from './TasksDifferences';
import TasksSummary from './TasksSummary';

interface ItemInventoryFreeProps {
  tasks: [number, ShelfListing][];
}

const ItemInventoryFree = (props: ItemInventoryFreeProps & RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`/itemInventory/type/:type/differences`} exact render={() => <TasksDifferences {...props} />} />
      <Route path="/itemInventory/type/:type/summary" exact render={() => <TasksSummary {...props} />} />
      <Route path="/itemInventory/type/:type" exact render={(routeProps) => <FreeTasks {...props} {...routeProps} />} />
      <Route
        path="/itemInventory/type/:type/location/:inventoryLocationName"
        exact
        render={() => <FreeTasks {...props} />}
      />
    </Switch>
  );
};

export default connect()(withRouter(ItemInventoryFree)) as React.ComponentType<ItemInventoryFreeProps>;
