import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { IncomingTransferInterface } from '@meeva/service-client-core/interfaces/IncomingTransferInterface';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import * as IncomingTransferHelper from '../utils/incomingTransferHelper';

const IncomingTransferDetail = () => {
  const { id } = useParams() as { id: string };
  const tableData: IncomingTransferInterface | undefined = useLiveQuery(() => IncomingTransferHelper.getEntryById(id));
  const [pagination, setPagination] = useState({ currentPage: 0, qtyRows: 10 });

  return (
    <Container>
      <Paper>
        {tableData ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Artikelnummer</TableCell>
                  <TableCell>Beschreibung</TableCell>
                  <TableCell>Menge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.lineItems
                  .slice(
                    pagination.currentPage * pagination.qtyRows,
                    pagination.currentPage * pagination.qtyRows + pagination.qtyRows
                  )
                  .map((data, i) => (
                    <TableRow key={i}>
                      <TableCell>{data.item.customItemNo || data.item.itemNo}</TableCell>
                      <TableCell>{data.item.name}</TableCell>
                      <TableCell>{data.unitCount}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              labelRowsPerPage="Zeilen:"
              count={tableData.lineItems.length}
              page={pagination.currentPage}
              rowsPerPage={pagination.qtyRows}
              onPageChange={(_, value) => setPagination((prevState) => ({ ...prevState, currentPage: value }))}
              onRowsPerPageChange={(event) =>
                setPagination((prevState) => ({
                  ...prevState,
                  qtyRows: Number(event.target.value) || 100,
                }))
              }
            />
          </TableContainer>
        ) : (
          <Typography color="textSecondary" gutterBottom>
            Wird geladen...
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default IncomingTransferDetail;
